import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import jQuery from 'jquery'
import VueMeta from 'vue-meta'
import 'buefy/dist/buefy.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
  faFacebook,
  faFacebookF,
  faFontAwesome,
  faInstagram,
  faTwitter,
  faWhatsapp,
 } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faBars, faPlus, faMinus, faChevronUp, faChevronDown, faChevronRight, faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import { VueRecaptcha } from 'vue-recaptcha-v3';
import JwPagination from 'jw-vue-pagination';

Vue.component('jw-pagination', JwPagination);
Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(faCheck, 
  faFontAwesome,
  faFacebookF,
  faInstagram,
  faWhatsapp,
  faFacebook, 
  faTwitter, 
  faBars, 
  faPlus,
  faMinus,
  faChevronUp,
  faChevronDown, 
  faChevronRight, 
  faChevronLeft)

Vue.config.productionTip = false

Vue.use(Buefy, FontAwesomeIcon, axios, VueMeta, VueRecaptcha, {
  refreshOnceOnNavigation: true,
  siteKey: '6LcZz6gdAAAAAF7uDzOaN_p49Xoq9Bbe_wfbwEWh',
  loaderOptions: {
    useRecaptchaNet: true
  }
})

global.jQuery = jQuery
global.$ = jQuery



new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
