<template>
    <div>
    <header>
        <div class="bar">
            <div class="container is-fluid">
                <div class="columns">
                    <div class="column f-karla f-left">
                        <a :href="link_whatsapp_bar">
                            <img :src="domain.concat(iconImg)" alt="" class="icon-header">
                            <div> {{ captionImg  }}</div>
                        </a>
                    </div>
                    <div class="column f-karla">
                            <p>{{ responseHeader.text_bar }}</p>
                    </div>
                    <div class="column f-right">
                        <ul class="nav-social-menu">
                            <li v-for="(sosmed, index) in responseHeader.social_media_bar" :key="index">
                                <a :href="sosmed.link"> 
                                    <img v-if="sosmed.icon.url != null" :src="domain.concat(sosmed.icon.url)" alt="" class="icon-header">
                                </a>
                            </li>
                            <!-- <li><a href=""> 
                                <font-awesome-icon :icon="['fab', 'facebook-f']" />
                            </a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>        
        <div class="">
            <a href="/"><img :src="domain.concat(logo)" alt="" class="navbar-logo"></a>
            <!-- <img src="../assets/img/logo-min.png" alt=""> -->
        </div>
        <div class="navbar-menu" id="navbar-menu">
            <div class="container is-fluid">
                <div class="columns">
                    <div class="column f-karla f-left fixed" id="f-left" style="opacity:0;" >
                        <!-- <font-awesome-icon :icon="['fab', 'whatsapp']" />  -->
                        <a :href="link_whatsapp_fixed">
                            <img :src="domain.concat(iconWhatsapp)" alt="" class="icon-header">
                            <div> {{ captionImg  }} </div>
                        </a>
                    </div>
                    <div class="column is-two-thirds">
                            <ul class="nav-menu f-karla" id="nav-menu"> 
                                <li v-bind:class="{ 'menuActive' : url_current === menu_link.link }" id="sub-menu" v-for="(menu_link, index) in responseHeader.menu_fixed_center" :key="menu_link.id" :value="index">
                                    <a v-if="menu_link.menu_product == true" href="#">{{ menu_link.caption }}</a>
                                    <a v-else :href="menu_link.link">{{ menu_link.caption }}</a>
                                    <ul v-if="menu_link.menu_product == true" id="sub-menu">
                                        <span v-for="(submenu, indexSubMenu) in responseCategory" :key="submenu.indexSubMenu" :value="indexSubMenu">
                                            <li v-if="submenu.products.length != 0" id="submenu-submenu">
                                                <a :href="$router.resolve({ name: 'List_Product', params: { slug: submenu.slug } }).href" >{{ submenu.category_name }} 
                                                        <span v-if="submenu.products.length">
                                                                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                                        </span></a>
                                                    <ul id="submenu-submenu" v-if="submenu.products.length">
                                                        <li v-for="(submenuSubmenu, indexSubmenuSubmenu) in submenu.products" :key="submenuSubmenu.indexSubmenuSubmenu" :value="indexSubmenuSubmenu">
                                                            <a :href="$router.resolve({ name: 'Detail_Product', params: { slug: submenuSubmenu.slug } }).href">{{ submenuSubmenu.name }}</a>
                                                        </li>
                                                    </ul>
                                            </li>
                                        </span>
                                    </ul>
                                </li>
                            </ul>
                    </div>
                    <div class="column f-karla f-right fixed" id="f-right" style="opacity:0;">
                        <ul class="nav-social-menu">
                            <li v-for="(sosmed_menu_fixed, index) in responseHeader.social_media_menu_fixed" :key="sosmed_menu_fixed.id" :value="index"> 
                                <a :href="sosmed_menu_fixed.link"> 
                                    <img v-if="sosmed_menu_fixed.icon.url != null" :src="domain.concat(sosmed_menu_fixed.icon.url)" alt="" class="icon-header">
                                </a>
                            </li>
                            <!-- <li><a href=""> 
                                <font-awesome-icon :icon="['fab', 'instagram']" /> 
                            </a></li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <header class="header-mobile">
        <div class="container">
            <div class="columns is-mobile menu-mobile is-align-items-center">
                <div class="column">
                    <div id="bars-mobile">
                        <font-awesome-icon :icon="['fa', 'bars']" />
                    </div>
                </div>
                <div class="column">
                    <a href="/"><img :src="domain.concat(logo)" alt=""  class="logo-mobile"></a>
                </div>
                <div class="column"></div>
            </div>
        </div>
        <div class="container menu-hidden" id="menu-hidden">
            <div class="">
                <div class="">
                    <section class="sidebar-layout" style="display:none">
                        <b-sidebar
                            position="static"
                            :delay="expandWithDelay ? 500 : null"
                            type="is-light"
                            open
                        >
                                <ul class="nav-menu-mobile">
                                    <li v-for="(menu_link, index) in responseHeader.menu_fixed_center" :key="menu_link.id" :value="index">
                                            <a id="sub-menu" v-if="menu_link.menu_product == true">{{ menu_link.caption }} 
                                                <span v-if="menu_link.menu_product == true" class="icon">
                                                                <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                                </span>
                                            </a>
                                            <a :href="menu_link.link" v-else>{{ menu_link.caption }}</a>
                                            <span id="sub-menu" style="display: none; "> 
                                                <ul v-if="menu_link.menu_product == true">
                                                    <span v-for="(submenu, indexSubMenu) in responseCategory" :key="submenu.indexSubMenu" :value="indexSubMenu">
                                                    <li v-if="submenu.products.length !== 0">
                                                            <a id="submenu-submenu" :href="$router.resolve({ name: 'List_Product', params: { slug: submenu.slug } }).href">{{ submenu.category_name }} 
                                                                <span v-if="submenu.products.length" class="icon">
                                                                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                                                                </span></a>
                                                                <ul id="submenu-submenu" v-if="submenu.products.length">
                                                                    <li v-for="(submenuSubmenu, indexSubmenuSubmenu) in submenu.products" :key="submenuSubmenu.indexSubmenuSubmenu" :value="indexSubmenuSubmenu">
                                                                        <a :href="$router.resolve({ name: 'Detail_Product', params: { slug: submenuSubmenu.slug } }).href">{{ submenuSubmenu.name }}</a>
                                                                    </li>
                                                                </ul>
                                                    </li>
                                                    </span>
                                                </ul>
                                            </span>
                                        </li>
                                </ul>
                        </b-sidebar>
                    </section>
                    
                </div>
            </div>
        </div>
    </header>

        <router-view/>
    </div>
</template>

<script>
import jQuery from 'jquery'
const axios = require('axios')
export default {
    name: 'Header',
    data() {
        return {
            expandWithDelay: false,
            isActive: false,
            responseHeader: {},
            domain: process.env.VUE_APP_STRAPI_URL,
            responseCategory: [],
            iconImg: '',
            captionImg: '',
            logo: '',
            iconWhatsapp: null,
            link_whatsapp_bar: null,
            link_whatsapp_fixed: null
        }
    },
    created(){
        axios.get(`${process.env.VUE_APP_STRAPI_URL}/header`).then(response => {
            this.responseHeader = response.data
            this.iconImg = this.responseHeader.whatsapp_bar.icon.url
            this.captionImg = this.responseHeader.whatsapp_bar.description
            this.logo = this.responseHeader.logo.url
            this.iconWhatsapp = this.responseHeader.whatsapp_menu_fixed.icon.url
            this.link_whatsapp_bar = this.responseHeader.whatsapp_bar.link
            this.link_whatsapp_fixed = this.responseHeader.whatsapp_menu_fixed.link
        })
        axios.get(`${process.env.VUE_APP_STRAPI_URL}/category-products`).then(responseForSubMenu => {
            this.responseCategory = responseForSubMenu.data
        })
    },
    methods: {
        
    },
    computed: {
        url_current: function(){
                return window.location.href
        }
    },
    mounted() {
        window.addEventListener('scroll', function() {
            const scrollHeight = window.pageYOffset;
                if (scrollHeight > 5) {
                    document.getElementById("navbar-menu").style.top = 0;
                    // document.getElementById("navbar-menu").style.boxShadow = "0px 1px 10px black";
                    jQuery( "#f-left" ).css("opacity","1");
                    jQuery( "#f-right" ).css("opacity","1");
                    jQuery( "#nav-menu" ).css("padding","9px 0px 8px");
                } else {
                    document.getElementById("navbar-menu").style.top = '138px';
                    // document.getElementById("navbar-menu").style.boxShadow = "0px 0px 0px black";
                    jQuery( "#f-left" ).css("opacity","0");
                    jQuery( "#f-right" ).css("opacity","0");
                }
        });

        jQuery( document ).ready(function() {
                jQuery("#bars-mobile").click(function(){

                    jQuery( ".sidebar-layout" ).toggle("slow");
                    jQuery("a#sub-menu").click(function(){
                        jQuery( "span#sub-menu" ).toggle();
                        jQuery( "span.icon" ).toggleClass("rotate-icon")
                    })

                    jQuery("a#submenu-submenu").click(function(){
                        jQuery( "ul#submenu-submenu" ).toggle();
                    })
                })

                 jQuery("#bars-mobile").click(function(){
                        jQuery("span.icon.is-small").addClass("for-toggle");
                 });
        });
        
        
    }
}

</script>
