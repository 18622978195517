<template>
    <div>
        <Header/>
        <div class="title-page is-fluid is-section-1 faq-title" :style="{ backgroundImage: `url(${domain.concat(bg_title)})` }">
             <h1 class="title is-1 p-25 f-spectral">{{ resultPage.title_page }}</h1>
            <img src="../assets/img/separator-profile.png" alt="" class="separator">
        </div>

        <div class="container is-fluid section-2-faq">
            <div class="wrap-title-faq">
                <h1 class="title is-1 f-spectral title-faq">FREQUENT QUESTIONS</h1>
                <img src="../assets/img/separator.png" alt="" class="separator">
            </div>
            <div class="columns">
                <div class="column toogle-faq">
                    <b-collapse
                        class="card"
                        animation="slide"
                        v-for="(collapse, index) of arrayFaq"
                        :key="collapse.id"
                        :open="isOpen == index"
                        @open="isOpen = index">
                        <template #trigger="props">
                            <div
                                class="card-header"
                                role="button"
                                :class="props.open ? 'active-header' : 'normal-header'"
                            >
                                <a class="card-header-icon">
                                    <!-- <b-icon
                                        :icon="props.open ? 'menu-down' : 'menu-up'">
                                    </b-icon> -->
                                    <font-awesome-icon :icon="props.open ? ['fas', 'minus'] : ['fas', 'plus']" />
                                </a>
                                <p class="card-header-title f-spectral">
                                    {{ collapse.title }}
                                </p>
                            </div>
                        </template>
                        <div class="card-content f-karla">
                            <div class="content" v-html="collapse.description">
                            </div>
                        </div>
                    </b-collapse>
                </div>
            </div>
        </div>
        <Footer/>
        <router-view/>
    </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
const axios = require('axios')
export default {
    name: 'Faq',
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription }
            ]    
        }
    },
    data() {
            return {
                isOpen: 0,
                collapses: [
                {
                    title: 'Berapa lama estimasi pengerjaan untuk pembuatan rumah bibit ? ',
                    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam eget diam magna. In vel egestas tortor, semper bibendum lectus. Vestibulum ac dapibus nulla, et mollis tellus. Cras ut tempor elit. Vivamus pharetra dictum auctor.'
                }
                ],
                responseFaq: null,
                metaTitle: null,
                metaDescription: null,
                arrayFaq: [],
                resultPage: {},
                bg_title: null,
                domain: process.env.VUE_APP_STRAPI_URL,
            }
    },
    created() {
        axios.get(`${process.env.VUE_APP_STRAPI_URL}/faq`).then(responseFaq => {
            this.resultPage = responseFaq.data
            this.bg_title = this.resultPage.background_title_page.url
            this.arrayFaq = responseFaq.data.Faq
            this.metaTitle = responseFaq.data.seo.metaTitle
            this.metaDescription = responseFaq.data.seo.metaDescription
        })
    },
    components: {
        Header,
        Footer
    },
}
</script>
