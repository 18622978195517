<template>
    <div>
            <div class="container is-fluid fullwidth">
                <footer class="f-karla">
                    <div class="columns columns-tablet">
                        <div class="column">
                            <a href="/"><img :src="domain.concat(logoFooter)" alt="" class="logo-footer"></a> 
                            <p class="f-karla desc-footer">{{ responseFooter.short_description }}</p>
                        </div>
                        <div class="column">
                            <div class="title-footer">{{ responseFooter.title_footer_2 }}</div>
                            <div class="content-footer">
                                <div class="contact-footer" v-for="(sosmed, index) in responseFooter.sosmed_with_caption" :value="index" :key="sosmed.id">
                                    <!-- <div class="img-contact-footer">
                                            <img v-if="sosmed.icon.url != null" :src="domain.concat(sosmed.icon.url)" alt="">
                                    </div>
                                    <a :href="sosmed.link">{{ sosmed.description }}</a>  -->
                                    <a :href="sosmed.link" class="wrap-contact-footer">
                                        <div class="img-contact-footer">
                                            <img v-if="sosmed.icon.url != null" :src="domain.concat(sosmed.icon.url)" alt="">
                                        </div>
                                        {{ sosmed.description }}
                                    </a>
                                </div>
                                <!-- <div class="contact-footer ">
                                    <div class="img-contact-footer">
                                        <img src="../assets/img/logo/phone.png" alt="">
                                    </div>
                                    0813 133 77777  
                                </div> -->
                            </div>
                        </div>
                        <div class="column">
                            <div class="title-footer">{{ responseFooter.title_footer_3 }}</div>
                            <div class="content-footer">
                                <div class="o-hours" v-html="responseFooter.opening_hours"></div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="title-footer">{{ responseFooter.title_footer_4 }}</div>
                            <div class="content-footer">
                                <div class="menu">
                                    <ul>
                                        <li v-for="(menu, index) in responseFooter.menu_footer" :value="index" :key="menu.id" ><a :href="menu.link">{{ menu.caption }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="column">
                            <div class="title-footer">{{ responseFooter.title_footer_5 }}</div>
                            <div class="content-footer">
                                <div class="p-10"> Your Email </div>
                                <div class="f-karla">
                                    <form action="#" class="form-subcribe" @submit.prevent="formSubscibe">
                                        <!-- <vue-recaptcha ref="recaptcha" size="invisible" :sitekey="sitekey" @verify="formSubscibe" @expired="onCaptchaExpired" />  -->
                                        <b-field>
                                            <b-input placeholder="Email" v-model="email" type="email" required></b-input>
                                        </b-field>
                                        <button class="f-open-sans">Subscribe</button> 
                                    </form>
                                    <div v-if="message_success != null">
                                        <div class="notif-subscribe">
                                            {{ message_success }}
                                        </div>
                                    </div>
                                </div>

                                <div class="sosmed-footer">
                                    <ul>
                                        <li v-for="(sosmedFooter, index) in responseFooter.sosmed_footer" :value="index" :key="sosmedFooter.id" >
                                            <a :href="sosmedFooter.link">
                                                <img v-if="sosmedFooter.icon.url != null" :src="domain.concat(sosmedFooter.icon.url)" alt="">
                                            </a>
                                        </li>
                                        <!-- <li><a href=""> 
                                            <font-awesome-icon :icon="['fab', 'facebook-f']" />
                                        </a></li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="copyright">{{ responseFooter.copyright_text }}</div>
                </footer>
            </div>
        <router-view/>
    </div>
</template>

<script>
const axios = require('axios')
import { VueRecaptcha } from 'vue-recaptcha';
/* eslint-disable */
export default{
    name: 'Footer',
    data() {
        return {
            email: null,
            message_success: null,
            responseFooter: {},
            domain: process.env.VUE_APP_STRAPI_URL,
            emailTemplate: null,
            logoFooter: null,
            // sitekey: '6LcZz6gdAAAAAF7uDzOaN_p49Xoq9Bbe_wfbwEWh'    
        }
    },
    methods: {
        formSubscibe() {
            axios({
                method: 'post',
                url: `${this.domain}/form-subscribes`,
                data: {
                    email: this.email,
                    // recaptchaToken: recaptchaToken
                },
                }).then((response) => {
                    if(response.status == 200) {
                        this.message_success = "Terimakasih telah berlangganan."

                            axios({
                                method: 'post',
                                url: `${this.domain}/email-subscribes`,
                                data: {
                                    email: this.email,
                                }
                            }).then(() => {
                                location.reload(true)
                            })

                    } 
                })

            // this.$refs.recaptcha.execute()
        },
        // onCaptchaExpired () { this.$refs.recaptcha.reset() } 
    },
    created() {
        axios.get(`${this.domain}/footer`).then(responseFooter => {
            this.responseFooter = responseFooter.data
            this.logoFooter= this.responseFooter.logo.url
        })
    },
    components: { 
        VueRecaptcha 
    }
}
</script>

<style>
#template_email_contact {
    background: #ECECEC;
    padding: 78px;
}
.title-content {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #142E70;
    margin-bottom: 30px;
}
.subcontent-email p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #363636;
    font-family: "Karla";
}
.footer-email {
    margin: 32px 0px;
    font-family: "Karla";
}
.link_to_web {
    font-size: 17px;
    font-family: "Karla";
    color: #142E70;
}
.footer-email .nav-social-menu{
    float: none;
    display: flex;
    justify-content: center;
    margin-right: 0px;
}
.footer-email p {
    margin-bottom: 24px !important;
}
.footer-email .desc-footer {
    font-size: 14px;
    font-family: "Karla";
    color: #A7A7A7;
}
.footer-email .copyright-footer {
    font-family: "Karla";
    font-size: 16px;
    color: #A7A7A7;
}
.content-email {
    background: #fff;
}
.subcontent-email {
    padding: 30px 45px 80px;
    font-family: "Karla";
}
.content-email img{
    width: 100%;
}
.content-email {
    text-align: left !important;
}
</style>
