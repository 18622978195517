<template>
    <div>
        <Header/>
        <div class="title-page is-fluid is-section-1 profile-title" :style="{ backgroundImage: `url(${domain.concat(bg_title)})` }">
             <h1 class="title is-1 p-25 f-spectral">{{ responseProfile.title_page }}</h1>
            <img src="../assets/img/separator-profile.png" alt="" class="separator">
        </div>
        
        <div class="container is-fluid section-1-profile p-mobile">
            <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths img-left">
                    <img :src="domain.concat(imageAbout)" alt="" class="">
                </div>
                <div class="column is-two-fifths">
                    <h1 class="title is-1 f-spectral">{{ responseProfile.title_about}}</h1>
                    <img src="../assets/img/separator.png" class="p-25 separator" alt="">
                    <p class="f-karla">{{ responseProfile.description_about }}</p>
                </div>
                <div class="column"></div>
            </div>
        </div>
        <div class="container is-fluid section-2-profile f-karla"  :style="{ backgroundImage: `url(${domain.concat(bg_animation)})` }" >
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <div class="box-count">
                            <img :src="domain.concat(img_1)" alt="">
                            <div id="count_1">
                                <animated-number
                                :value="count_1"
                                :duration="5000"
                                :formatValue="formatNumber"
                                />
                            </div>
                            <div class="desc-count">{{ responseProfile.caption_animation_counting_1 }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="box-count">
                            <img :src="domain.concat(img_2)" alt="">
                            <div id="count_2">
                                <animated-number
                                :value="count_2"
                                :duration="5000"
                                :formatValue="formatNumber"
                                />
                            </div>
                            <div class="desc-count">{{ responseProfile.caption_animation_counting_2 }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="box-count">
                            <img :src="domain.concat(img_3)" alt="">
                            <div id="count_3">
                                <animated-number
                                :value="count_3"
                                :duration="5000"
                                :formatValue="formatNumber"
                                />
                            </div>
                            <div class="desc-count">{{ responseProfile.caption_animation_counting_3 }}</div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="box-count">
                            <img :src="domain.concat(img_4)" alt="">
                            <div id="count_4">
                                <animated-number
                                :value="count_4"
                                :duration="5000"
                                :formatValue="formatNumber"
                                />
                            </div>
                            <div class="desc-count">{{ responseProfile.caption_animation_counting_4 }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container is-fluid section-3-profile p-mobile">
            <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                    <h1 class="title is-1 f-spectral">{{ responseProfile.title_our_performance }}</h1>
                    <img src="../assets/img/separator.png" class="p-25 separator" alt="">
                    <p class="f-karla">{{ responseProfile.description_our_performance }}</p>
                </div>
                <div class="column is-two-fifths img-right">
                    <img :src="domain.concat(imageOurPerformance)" alt="">
                </div>
                <div class="column"></div>
            </div>
        </div>
        <div class="container section-4-profile logo-slick-profile">
            <div class="title-gold p-25 f-karla"> {{ responseProfile.title_client }} </div> 
            <h1 class="title is-1 f-spectral title-logo-profile">{{ responseProfile.subtitle_client }}</h1>
            <img src="../assets/img/separator.png" alt="" class="">
        </div>
        <div class="container is-fluid">
                <div class="lsp2">
                    <VueSlickCarousel v-bind="settings_profile_logo" v-if="responseForFE.length">
                        <div class="logo-profile" v-for="(logo_client, index) in responseForFE" :value="index" :key="logo_client.id">
                                <img :src="domain.concat(logo_client.img_logo.url)" alt="">
                        </div>
                    </VueSlickCarousel>
                </div>
        </div>
        <Footer/>
    <router-view />
    </div>
</template>

<script type="text/javascript">

import AnimatedNumber from "animated-number-vue";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Header from '../components/Header'
import Footer from '../components/Footer'
const axios = require('axios')
export default {
    name: 'Profile',
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
    data() {
        return {
            responseForFE: [],
            settings_profile_logo: {
                "arrows": false,
                "rows": 3,
                "dots": true,
                "infinite": true,
                "slidesToShow": 8,
                "slidesToScroll": 3,
                "speed": 500,
                "slidesPerRow": 1,
                "responsive": [
                    {
                    "breakpoint": 769,
                    "settings": {
                        "slidesToShow": 3,
                        "slidesToScroll": 3,
                        "initialSlide": 3
                    }
                    },
                    {
                    "breakpoint": 480,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2
                    }
                    }
                ]
            },
            responseProfile: {},
            domain: process.env.VUE_APP_STRAPI_URL,
            bg_title: null,
            bg_animation: null,
            count_1: null,
            count_2: null,
            count_3: null,
            count_4: null,
            img_1: '',
            img_2: '',
            img_3: '',
            img_4: '',
            imageAbout: '',
            imageOurPerformance: '',
            metaTitle: null,
            metaDescription: null,
            keywords: null
        }
    },
    components: {
        VueSlickCarousel,
        Header,
        Footer,
        AnimatedNumber
    },
    created() {
        axios
        .get(`${this.domain}/profile`)
        .then(responseProfile => {
            this.responseProfile = responseProfile.data
            this.bg_title = this.responseProfile.background_title_page.url
            this.bg_animation = this.responseProfile.background_animation_couting.url
            this.count_1 = this.responseProfile.number_animation_counting_1
            this.count_2 = this.responseProfile.number_animation_counting_2
            this.count_3 = this.responseProfile.number_animation_counting_3
            this.count_4 = this.responseProfile.number_animation_counting_4
            this.img_1 = this.responseProfile.img_animation_counting_1.url
            this.img_2 = this.responseProfile.img_animation_counting_2.url
            this.img_3 = this.responseProfile.img_animation_counting_3.url
            this.img_4 = this.responseProfile.img_animation_counting_4.url
            this.imageAbout  = this.responseProfile.image_about.url
            this.imageOurPerformance = this.responseProfile.img_our_performance.url
            this.metaTitle = this.responseProfile.seo.metaTitle
            this.metaDescription = this.responseProfile.seo.metaDescription
            this.keywords = this.responseProfile.seo.keywords
        })
        
        axios.get(`${this.domain}/logo-clients`).then(responseLogoClient => {
            this.responseForFE = responseLogoClient.data
        })
    },
    methods: {
        formatNumber(value) {
            return `${value.toFixed()}`;
        }      
    },
    mounted() {

    }
}
</script>
