import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import Home from '../views/Home'
import Profile from '../views/Profile'
import Contact from '../views/Contact'
import Detail_Product from '../views/Detail_Product'
import List_Product from '../views/List_Product'
import Gallery from '../views/Gallery'
import Faq from '../views/Faq'
import EmailTemplateContact from '../views/EmailTemplateContact'
import EmailTemplateSubscribe from '../views/EmailTemplateSubscribe'


Vue.use(VueRouter)
Vue.use(Meta, { 
    refreshOnceOnNavigation: true
})

const routes = [
    {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Home'
        }
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/detail-product/:slug',
        name: 'Detail_Product',
        component: Detail_Product
    },
    {
        path: '/list-product/:slug',
        name: 'List_Product',
        component: List_Product
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq
    },
    {
        path: '/email-template-contact',
        name: 'EmailTemplateContact',
        component: EmailTemplateContact
    },
    {
        path: '/email-template-subscribe',
        name: 'EmailTemplateSubscribe',
        component: EmailTemplateSubscribe
    },
];

const router = new VueRouter({
        mode: 'history',
        base: process.env.BASE_URL,
        routes,
    })

export default router