<template>
    <div>
        <Header/>
        <div class="title-page is-fluid is-section-1 section-1-footer contact-title" :style="{ backgroundImage: `url(${domain.concat(bg_title)})` }">
            <h1 class="title is-1 p-25 f-spectral">{{ responseContact.title_page }}</h1>
            <img src="../assets/img/separator-profile.png" alt="" class="separator">
        </div>

        <div class="container section-2-footer is-fluid">
            <div class="columns">
                <div class="column"></div>
                <div class="column is-two-fifths">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63372.056458452396!2d107.5700982!3d-6.9200411!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e62492dd6a25%3A0xc58a91bec4f9f50a!2sTujuh%20Decor!5e0!3m2!1sid!2sid!4v1638434162681!5m2!1sid!2sid" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
                <div class="column is-two-fifths">
                    <h1 class="title is-1 f-spectral">{{ responseContact.title_contact }}</h1>
                    <img src="../assets/img/separator.png" alt="" class="p-25 separator">

                    <p class="f-karla"> {{ responseContact.address }} </p> 
                    <p class="f-karla"> <a :href="responseContact.link_phone">{{ responseContact.phone }}</a> </p>  
                    <p class="f-karla p-25"> <a :href="responseContact.link_email">{{ responseContact.email }}</a> </p> 

                    <div class="o-hours-with-sosmed f-karla">
                        <div v-html="responseContact.opening_hours"></div>

                        <div class="sosmed-contact">
                             <ul>
                                <!-- <li><a href=""> 
                                    <font-awesome-icon :icon="['fab', 'facebook-f']" />
                                </a></li>
                                <li> -->
                                <li  v-for="(social, index) in responseContact.social_media" :value="index" :key="social.id">
                                    <a :href="social.link"> 
                                        <img :src="domain.concat(social.icon.url)" alt="">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div class="column"></div>
            </div>
        </div>

        <div class="container section-3-footer is-fluid">
            <h1 class="title is-1 f-spectral">{{ responseContact.title_form }}</h1>
            <img src="../assets/img/separator.png" alt="" class="p-50 separator"> 
        </div>

    <form action="#" id="contact-form" class="m-50" @submit.prevent="postContact">
        <div class="container is-fluid">
            <div class="columns m-25">
                <div class="column is-1">
                    
                </div>
                <div class="column column-center">
                    <div class="columns">
                        <div class="column">
                            <b-field>
                                <b-input expanded v-model="name" required placeholder="NAME" ></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field>
                                <b-input v-model="phone" required placeholder="TELEPHONE" id="phone-contact" ></b-input>
                            </b-field>
                        </div>
                        <div class="column">
                            <b-field>
                                <b-input v-model="email" type="email" required placeholder="EMAIL" ></b-input>
                            </b-field>
                        </div>
                    </div>
                </div>
                <div class="column is-1">
                </div>
            </div>
        </div>

        <div class="container is-fluid">
            <div class="columns">
                <div class="column is-1">
                    
                </div>
                <div class="column column-center">
                    <b-field>
                        <b-input type="textarea"
                            placeholder="WRITE YOUR MESSAGE HERE...." required v-model="message">
                        </b-input>
                    </b-field>

                    <div class="recaptcha_failed f-karla">
                        <!-- <vue-recaptcha
                            sitekey="6LcwY6oUAAAAAKsO2ApLQ_nnC_-ht0yrBxT1ALcH"
                            @verify="recaptchaVerify"
                        ></vue-recaptcha>
                        
                        <small v-if="errorCaptcha != ''">{{ errorCaptcha }}</small> -->
                        
                        <!-- <button @click="recaptcha">Execute recaptcha</button> -->
                    </div>

                    <button>Submit</button>
                </div>
                <div class="column is-1">
                    
                </div>
            </div>
        </div>
    </form>
    <div class="container is-fluid">
        <div class="columns">
                <div class="column is-1">
                    
                </div>
                <div class="column column-center">
                    <div v-if="message_success != ''">
                        <div class="success-message f-karla"> {{ message_success }} </div>
                    </div>
                </div>
                <div class="column is-1">

                </div>
        </div>
    </div>

    <Footer/>
        <router-view />
    </div>
</template>

<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, { siteKey: process.env.RECAPTCHA_SITE_KEY })
const axios = require('axios')
/* eslint-disable */
export default {
    name: 'Contact',
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
    // metaInfo: {
    //     title: 'Contact',
    //     titleTemplate: '%s - Tujuh Decor',
    //     htmlAttrs: {
    //         lang: 'en',
    //         amp: true
    //     }
    // },
    data() {
        return {
            name: '',
            email: null,
            phone: null,
            message: '',
            responseContact: {},
            message_success: '',
            domain: process.env.VUE_APP_STRAPI_URL,
            emailTemplate: null,
            metaTitle: '',
            metaDescription: '',
            keywords: null,
			// recaptcha: null,
            errorCaptcha: '',
            bg_title: null,
        }
    },
    components: {
        Header,
        Footer
    },
    methods: {
        // recaptchaVerify( response ) {
		// 	this.recaptcha = response
        //     console.log(response)
		// },
        showmenu() {  
            var x = document.getElementById("menu-hidden");
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        },
        show_cat_product() {
            var show_cat = document.getElementById("show_cat");
            show_cat.classList.toggle("show-cat-product");
        },
        show_per_product(){
            var show_product = document.getElementById("show_product");
            show_product.classList.toggle("show-list-product");
        },
        postContact() {
                axios({
                    method: 'post',
                    url: `${this.domain}/form-contacts`,
                    data: {
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        message: this.message
                    },
                }).then((response) => {
                    if(response.status == 200) {
                        this.message_success = "Terimakasih telah menghubungi kami, tunggu beberapa saat untuk mendapatkan response"

                        axios({
                            method: 'post',
                            url: `${this.domain}/email-contacts`,
                            data: {
                                email: this.email,
                                name: this.name,
                                phone: this.phone,
                                message: this.message
                            }
                        }).then(() => {
                            location.reload(true)
                        })

                    } 
                })
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('postContact')
        }
    },
    template: '<button @click="recaptcha">Execute recaptcha</button>',
    created() {
        axios.get(`${this.domain}/contact`).then(responseContact => {
            this.responseContact = responseContact.data
            this.bg_title = this.responseContact.background_title_page.url
            this.metaTitle = this.responseContact.seo.metaTitle
            this.metaDescription = this.responseContact.seo.metaDescription
            this.keywords = this.responseContact.seo.keywords
            
        })
    },
    mounted() {
        var log = document.getElementById('phone-contact');
        log.addEventListener('keypress', function(e) {
            let charCode = (e.which) ? e.which : event.keyCode
            if (charCode < 48 || charCode > 57) {
                e.preventDefault()
                return false
            } else {
                return true
            }
        });

        
        const recaptcha = this.$recaptchaInstance
        recaptcha.hideBadge()
    }
}
</script>

<style>
#template_email_contact {
    background: #ECECEC;
    padding: 78px;
}
.title-content {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #142E70;
    margin-bottom: 30px;
}
.subcontent-email p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #363636;
    font-family: "Karla";
}
.footer-email {
    margin: 32px 0px;
    font-family: "Karla";
}
.link_to_web {
    font-size: 17px;
    font-family: "Karla";
    color: #142E70;
}
.footer-email .nav-social-menu{
    float: none;
    display: flex;
    justify-content: center;
    margin-right: 0px;
}
.footer-email p {
    margin-bottom: 24px !important;
}
.footer-email .desc-footer {
    font-size: 14px;
    font-family: "Karla";
    color: #A7A7A7;
}
.footer-email .copyright-footer {
    font-family: "Karla";
    font-size: 16px;
    color: #A7A7A7;
}
.content-email {
    background: #fff;
}
.subcontent-email {
    padding: 30px 45px 80px;
    font-family: "Karla";
}
.content-email img{
    width: 100%;
}
.content-email {
    text-align: left !important;
}
#contact-form iframe {
    width: 100% !important;
}
.recaptcha_failed {
    text-align: left;
    color: red;
    margin-bottom: 25px;
}
</style>