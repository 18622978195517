<template>
    <div>
        <div id="template_email_contact">
            <div class="container">
                <div style="text-align:center;">
                    <img src="../assets/img/logo.png" alt="">
                </div>
                <div class="content-email">
                    <img src="../assets/img/Hero.png" alt="">
                        <div class="subcontent-email">
                            <div class="title-content f-spectral">Terima Kasih Sudah Berlangganan</div>
                            <p>
                                Terima kasih banyak atas antusiasme Anda untuk berlangganan info-info seputar kami. Sejak berdiri di tahun 1962, Tujuh Decor senantiasa memberikan pelayanan terbaik untuk para pelanggannya dengan menyediakan beragam kebutuhan interior dan eksterior bangunan. Tujuh Decor didukung oleh tim yang profesional, berpengalaman, dan paham akan kebutuhan Anda.
                                <br><br>
                                Nantikan beragam informasi seputar layanan dan produk Tujuh Decor. Jika Anda tertarik, silakan hubungi kami via email atau WA ke nomor 081313377777, terima kasih.
                            </p>
                        </div>
                </div>
                <div class="footer-email">
                        <p><strong> Follow us on </strong></p>
                        <ul class="nav-social-menu">
                            <li><a href=""> 
                                <!-- <font-awesome-icon :icon="['fab', 'facebook-f']" /> -->
                                <img src="../assets/img/logo/facebook.png" alt="">
                            </a></li>
                            <li><a href=""> 
                                <!-- <font-awesome-icon :icon="['fab', 'instagram']" />  -->
                                <img src="../assets/img/logo/Instagram.png" alt="">
                            </a></li>
                            <li><a href=""> 
                                <!-- <font-awesome-icon :icon="['fab', 'twitter']" />  -->
                                <img src="../assets/img/logo/Twitter.png" alt="">
                            </a></li>
                        </ul>
                        <p><a href="" class="link_to_web">www.tujuhdecor.com</a></p>
                        <p class="desc-footer">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla a donec turpis ultricies. Nascetur facilisis a sed turpis aenean purus. Aliquet vel risus amet egestas orci dui id. Sit morbi venenatis ac sapien, risus, orci blandit. </p>
                        <p class="copyright-footer">Copyright © 2021, Roketin Teknologi Indonesia.</p>
                </div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'EmailTemplateSubscribe',
    data() {
        
    },
    methods: {
    },
    components: {
    },
}
</script>

<style>
#template_email_contact {
    background: #ECECEC;
    padding: 78px;
}
.title-content {
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #142E70;
    margin-bottom: 30px;
}
.subcontent-email p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1px;
    color: #363636;
    font-family: "Karla";
}
.footer-email {
    margin: 32px 0px;
    font-family: "Karla";
}
.link_to_web {
    font-size: 17px;
    font-family: "Karla";
    color: #142E70;
}
.footer-email .nav-social-menu{
    float: none;
    display: flex;
    justify-content: center;
    margin-right: 0px;
}
.footer-email p {
    margin-bottom: 24px !important;
}
.footer-email .desc-footer {
    font-size: 14px;
    font-family: "Karla";
    color: #A7A7A7;
}
.footer-email .copyright-footer {
    font-family: "Karla";
    font-size: 16px;
    color: #A7A7A7;
}
.content-email {
    background: #fff;
}
.subcontent-email {
    padding: 30px 45px 80px;
    font-family: "Karla";
}
.content-email img{
    width: 100%;
}
.content-email {
    text-align: left !important;
}
</style>