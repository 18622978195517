<template>
    <div>
        <Header/>
        <div class="wrap-title-product is-section-1">
            <div class="container is-fluid section-1-gallery">
                <div class="columns">
                    <div class="column">
                        <h1 class="title-gallery f-spectral" v-if="titleGallery != null">{{ titleGallery }}</h1>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="container section-2-gallery is-fluid">
            <div class="columns">
                <div class="column">

                </div>
                <div class="column">
                    
                    <div class="filter-wrap">
                        <div class="filter-button f-karla" id="filter-button" @click="showmenu"> 
                            Sort By
                            <div id="icon-filter-down"><font-awesome-icon :icon="['fas', 'chevron-down']"/></div>
                            <div id="icon-filter-up" style="display:none;"><font-awesome-icon :icon="['fas', 'chevron-up']"/></div>
                        </div>
                        <div class="filter-trigger" style="display: none">
                            <div class="wrap-filter">
                                <p class="f-karla">Category</p>

                                <form action="" @submit.prevent="getIdCategories">
                                    <div class="input-checkbox">
                                        
                                        <span v-for="(category, index) in responseCategoriesForGetId" :value="index" :key="category.id">
                                            <b-checkbox-button v-model="checkboxGroup"
                                                :native-value="category.id">
                                                {{ category.name_category }}
                                            </b-checkbox-button>
                                        </span>
                                        <!-- <b-checkbox-button v-model="checkboxGroup"
                                            native-value="b">
                                            Awning
                                        </b-checkbox-button> -->

                                    </div>
                                    <div class="footer-filter">
                                        <button class="button is-link">Apply</button>
                                        <b-button class="button is-light" @click="clearSelect">Clear All</b-button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div v-if="responseCategoriesForGetIdSelections == null">
        <div class="container section-2-gallery is-fluid">
            <div class="columns">

                <div class="container-gallery" v-if="this.getAllGallery && this.getAllGallery.length" >    
                                <div class="wrapper" v-for="(gallery, index) in getAllGallery" :value="index" :key="gallery.id" id="wrap-all-gallery">
                                    <a :href="domain.concat(gallery.image.url)" data-lightbox="image-1" >
                                        <div class="column wrap-img-gallery">
                                            <img :src="domain.concat(gallery.image.url)" alt="" >
                                            <div class="white-hover">
                                                <!-- {{ gallery }} -->
                                                <h1 class="title is-1 f-spectral">
                                                    {{ gallery.category.name_category }}
                                                </h1>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                        
                    <!-- <a href="https://i.ibb.co/cQw1S36/gallery2.png" data-lightbox="image-2">
                        <div class="column wrap-img-gallery">
                                <img src="https://i.ibb.co/cQw1S36/gallery2.png" alt="">
                                <div class="white-hover">
                                    <h1 class="title is-1 f-spectral">
                                        Curtain
                                    </h1>
                                </div>
                        </div>
                    </a> -->
                    
                </div>
            </div>
            <div>
                <button class="button-view-more f-karla" v-if="viewMoreButton" @click="loadMore">View More</button>
                <!-- {{ this.notif }} -->
            </div>
        </div>
        </div>

        <div v-else>
            <div class="container section-2-gallery is-fluid p-50">
                <div>
                    
                    <div class="container-gallery" v-if="this.responseCategoriesForGetIdSelections && this.responseCategoriesForGetIdSelections.length">
                        
                        <div class="wrapper" v-for="(imgSelections, index) in responseCategoriesForGetIdSelections" :value="index" :key="imgSelections.id">  
                                <a :href="domain.concat(imgSelections.image.url)" data-lightbox="image-2">
                                    <div class="column wrap-img-gallery">
                                            <img :src="domain.concat(imgSelections.image.url)" alt="">
                                            <div class="white-hover">
                                                <h1 class="title is-1 f-spectral">
                                                    {{ imgSelections.category.name_category }}
                                                </h1>
                                            </div>
                                    </div>
                                </a>
                        </div>
                    
                    </div> 

                </div>
                
            <!-- <div>
                <button class="button-view-more filter f-karla" @click="galleryToShow += 2">View More</button>
            </div> -->
            
            <div>
                <button class="button-view-more categories f-karla" v-if="viewMoreButton" @click="viewMore">View More</button>
                {{ notif }}
            </div>
            </div>

            <div class="container section-2-gallery is-fluid other-projects-wrap">
                <h1 class="title is-1 f-spectral others-project">Others Projects</h1>
                <div class="container-gallery">
                    <div class="wrapper" v-for="(imgExclude, index) in responseOtherProjects" :value="index" :key="imgExclude.id">
                            <a :href="domain.concat(imgExclude.image.url)" data-lightbox="image-3">
                                <div class="column wrap-img-gallery">
                                        <img :src="domain.concat(imgExclude.image.url)" alt="">
                                        <div class="white-hover">
                                            <h1 class="title is-1 f-spectral">
                                                {{ imgExclude.category.name_category }}
                                            </h1>
                                        </div>
                                </div>
                            </a>
                    </div>
                </div>
            </div>
            
        </div>

        <Footer/>
        <router-view/>
    </div>
</template>

<script src="../assets/lightbox/js/lightbox.js" type="text/javascript"></script>
<script type="text/javascript">
import Header from '../components/Header'
import Footer from '../components/Footer'
import jQuery from 'jquery'
import lightbox from '../assets/lightbox/js/lightbox'
const axios = require('axios')
export default {
    name: 'Gallery',
    metaInfo () {
        return {
            title:  this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
    data() {
        return {
            checkboxGroup: [],
            getAllGallery: null,
            domain: process.env.VUE_APP_STRAPI_URL,
            responseCategoriesForGetId: null,
            query: null,
            responseCategoriesForGetIdSelections: null,
            parseCheckboxGroup: null,
            toArray: null,
            newArr: [],
            checkboxGroup: [],
            getAllGalleryAfterClear: null,
            arraySelected : null,
            randomId: null,
            responseOtherProjects: null,
            metaTitle: '',
            metaDescription: '',
            keywords: '',
            galleryToShow: 3,
            isButtonLoadMore: true,
            startGallery: null,
            limitGallery: null,
            query: null,
            getId: null,
            viewMoreButton: true,
            notif: '',
            titleGallery: ''
        }
    },
    created() {
        axios.get(`${this.domain}/gallery-page`).then(responsePageGallery => {
            this.pageGallery = responsePageGallery.data
            this.titleGallery = responsePageGallery.data.title_page
            this.metaTitle = this.pageGallery.seo.metaTitle
            this.metaDescription = this.pageGallery.seo.metaDescription
            this.keywords = this.pageGallery.seo.keywords
        })
        axios.get(`${this.domain}/galleries?_start=0&_limit=9`).then(response => {
            this.getAllGallery = response.data
        })
        axios.get(`${this.domain}/categories`).then(responseCategories => {
            this.responseCategoriesForGetId = responseCategories.data
        })
    },
    methods: {
        showmenu() {  
            var x = document.getElementById("icon-filter-up");
            var y = document.getElementById("icon-filter-down");
            if (x.style.display === "none") {
                x.style.display = "block";
                y.style.display = "none";
            } else {
                x.style.display = "none";
                y.style.display = "block";
            }
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        getIdCategories() {
            let newArr = 'category=' + this.checkboxGroup.join('&category=')
            axios.get(`${this.domain}/galleries?${newArr}&_limit=9`).then(responseCategoriesForGetSelect => {
                this.responseCategoriesForGetIdSelections = responseCategoriesForGetSelect.data
            })
            
            var arraySelected = this.checkboxGroup
            // console.log(responseCategoriesForGetId.length)
            var randomId = this.getRandomInt(1, 4)
            if (!arraySelected.includes(randomId)) {
                axios.get(`${this.domain}/galleries?category=${randomId}`).then(responseExcludeArray => {
                    this.responseOtherProjects = responseExcludeArray.data
                })
            }
        },
        loadMore() {
            if(this.getAllGallery.length > 9){
                var start = this.getAllGallery.length + 9
            } else {
                var start = 9
            }

            if(this.getAllGallery.length >= 18){
                var limit = start + 9
            } else {
                var limit = 18
            }

            axios.get(`${this.domain}/galleries?_start=${start}&_limit=${limit}`).then(response2 => {
                console.log(response2.data.length)
                if(response2.data.length <= 9){
                    this.viewMoreButton = false
                }

                for(var i=0; i < response2.data.length; i++) {
                    this.getAllGallery.push(response2.data[i])
                }
            })
        },
        clearSelect() {
            this.responseCategoriesForGetIdSelections = null
            axios.get(`${this.domain}/galleries?_start=0&_limit=9`).then(response => {
                this.responseCategoriesForGetIdSelections = response.data
            })
            this.isButtonLoadMore = false
        },
        viewMore() {
            if(this.responseCategoriesForGetIdSelections.length == 9){
                var startGallery = 9
            } else {
                var startGallery = this.responseCategoriesForGetIdSelections.length + 9
            }

            // if(this.responseCategoriesForGetIdSelections.length >= 18){
                var limitGallery = startGallery + 9
            // }    
            
            let newArr = '&category=' + this.checkboxGroup.join('&category=')
            axios.get(`${this.domain}/galleries?_start=${startGallery}&_limit=${limitGallery}${newArr}`).then(responseCategoriesForGetSelect => {
                let data = responseCategoriesForGetSelect.data
                console.log(responseCategoriesForGetSelect.data.length)
                if(responseCategoriesForGetSelect.data.length <= 9){
                    this.viewMoreButton = false
                }
                data.forEach(value => {
                    this.responseCategoriesForGetIdSelections.push(value)
                });
            })

        }
    },
    computed: {
        url_current: function(){
                return window.location.href
        }
    },
    mounted() {
        if(window.location.href === "https://tujuhdecor.roketin.xyz/gallery" || window.location.href === "https://tujuhdecor.com/gallery") {
            jQuery( document ).ready(function() {
                    jQuery( ".lb-data .lb-number" ).css("opacity","0", "!important");
                    jQuery( ".lb-nav" ).css("opacity","0", "!important");
            });
        }

        jQuery("#filter-button").click(function(){
             jQuery( ".filter-trigger" ).toggle();
        })

        lightbox.option({ })

    },
    components: {
        Header,
        Footer
    },
}
</script>

<style>
  @import '../assets/lightbox/css/lightbox.css';
  .lightbox .lb-image {
      border: 0px;
  }
</style>