<template>
  <div>
    <Header/>
    <div class="container is-fluid fullwidth m-100">
        <b-carousel 
            arrow-hover: false
            :pause-info="pauseInfo"> 
            <b-carousel-item v-for="(slider, i) in homepage.Slider" :key="i" 
            :interval="interval"> 
                <section :class="`hero is-medium is-section-1 is-bg-banner`">
                    <div class="hero-body">
                        <div class="columns is-mobile">
                            <div class="column 
                            is-two-thirds-tablet
                            is-two-thirds-desktop
                            is-two-thirds-widescreen
                            is-two-thirds-fullhd 
                            is-half-mobile">
                                <h1 class="title-banner">{{slider.title}}</h1>
                                <img src="../assets/img/separator.png" alt=""><br>
                                <p class="m-50 f-karla desc-banner">{{slider.description}}</p>
                                <a :href="slider.link_button" class="button-general">
                                    <span class="button-1"></span>
                                    <span class="button-2"></span>
                                    <span class="text f-karla">{{slider.caption_button}}</span> 
                                </a>
                            </div>
                            <div class="column">
                                <img :src="url.concat(slider.image.url)" class="img-banner">
                            </div>
                        </div>
                    </div>
                </section>
            </b-carousel-item>
        </b-carousel>
    </div>

    <div class="container is-fluid m-100 section-home-1">
        <div class="title-gold p-25 f-karla"> {{ homepage.title_product }} </div>
        <h1 class="title is-1 p-25 f-spectral"> {{ homepage.subtitle_product }} </h1>
        <img src="../assets/img/separator.png" alt="" class="p-50 separator">
        <div class="columns p-50 columns-tablet section-home-2">
            <div class="column is-3" v-for="(product, index) in homepage.products.slice(0,4)" :value="index" :key="product.index">
                <div class="box-product p-25">
                    
                    <router-link :to="{ name: 'Detail_Product', params: { slug: product.slug } }"  id="check_jquery">
                    <div class="wrap-img p-50">
                        <img :src="url.concat(product.image_featured.url)" alt="">
                        <div class="overlay">
                            <router-link :to="{ name: 'Detail_Product', params: { slug: product.slug } }"  id="check_jquery">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="text f-karla">View Detail</span>
                            </router-link>
                        </div>
                    </div>
                    </router-link>
                    
                    <router-link :to="{ name: 'Detail_Product', params: { slug: product.slug } }"  id="check_jquery">
                        <h4 class="title is-4 title-product f-karla">{{ product.name }}</h4>
                    </router-link>
                </div>
            </div>
            <!-- <div class="column">
                <div class="box-product p-25">
                    <div class="wrap-img p-50">
                        <img src="../assets/img/pngwing 2.png" alt="">
                        <div class="overlay">
                            <a href="">
                                <span class="button-1"></span>
                                <span class="button-2"></span>
                                <span class="text f-karla">View Detail</span>
                            </a>
                        </div>
                    </div>
                    <h4 class="title is-4 title-product f-karla">Blind</h4>
                </div>
            </div> -->
        </div>      
    </div>

    <div class="container is-fluid fullwidth">           
        <div class="columns m-100">
            <div class="column bg-gradient is-three-fifths-fullhd is-two-thirds-widescreen">
                <h2 class="title-custom p-25 f-spectral">{{ homepage.title_why }}</h2>
                <div class="columns">
                    <div class="column">
                        <div class="why">
                            <h6 class="title-why">
                                <div class="img-why-top"><img src="../assets/img/check.png" alt=""></div>
                                <span class="subtitle-why f-open-sans">
                                    {{ homepage.title_why_1 }}
                                </span>
                            </h6>
                            <p class="content-why f-karla">{{ homepage.description_why_1 }}</p>
                        </div>
                        <div class="why">
                            <h6 class="title-why">
                                <div class="img-why-bottom" ><img src="../assets/img/check.png" alt=""></div>
                                <span class="subtitle-why f-open-sans">
                                    {{ homepage.title_why_2 }}
                                </span>
                            </h6>
                            <p class="content-why f-karla">{{  homepage.description_why_2 }}</p>     
                        </div>                   
                    </div>
                    <div class="column">
                        <div class="why">
                            <h6 class="title-why">
                                <div class="img-why-top"><img src="../assets/img/check.png" alt=""></div>
                                <span class="subtitle-why f-open-sans">
                                    {{ homepage.title_why_3 }}
                                </span>
                            </h6>
                            <p class="content-why f-karla">{{ homepage.description_why_3 }}</p>
                        </div>
                        <div class="why">
                            <h6 class="title-why">
                                <div><img src="../assets/img/check.png" alt=""></div>
                                <span class="subtitle-why f-open-sans">
                                    {{ homepage.title_why_4 }}
                                </span>
                            </h6>
                            <p class="content-why f-karla">{{  homepage.description_why_4 }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column bg-why" v-if="homepage.image_why.url != null">
                <img :src="url.concat(homepage.image_why.url)" alt="">
            </div>
        </div>
    </div>
    
    <div class="container is-fluid bg-testimonials">
        <div class="container">
            <div class="title-white p-25 f-karla"> {{ homepage.title_project }} </div>
            <h1 class="title is-1 p-25 f-spectral title-white">{{ homepage.subtitle_project }}</h1>
            <img src="../assets/img/separator-white.png" alt="" class="p-50 separator">
        </div>
    </div>

    <div class="container section-home-4">
            <div class="mg-min-185 p-testimonials">
                <VueSlickCarousel v-bind="settings_project_slick" v-if="homepage.Projects && homepage.Projects.length"> 
                    <div class="column-testimonial" v-for="(project, index) in homepage.Projects" :value="index" :key="project.id">
                        <div class="box-testimonial">
                            <div v-if="project.image_project.url != null">
                                <img :src="url.concat(project.image_project.url)" alt="">
                            </div>
                            <div class="overlay-testimonials">
                                <div class="name f-karla">{{ project.title_project }}</div>
                                <div class="position f-karla">{{ project.subtilte_project }}</div>
                                <p class="f-karla">{{ project.description_project }}</p>
                            </div>
                        </div>
                    </div>
                </VueSlickCarousel>
                <!-- <div class="column">
                    <div class="box-testimonial">
                        <img src="../assets/img/testimonial-min.png" alt="">
                        <div class="overlay-testimonials">
                            <div class="name">Julio Geo</div>
                            <div class="position">CEO, Lorem Ipsum</div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </div>
                    </div>
                </div> -->
            </div>
    </div>

    <div class="container logo-slick-home mg-185">
        <VueSlickCarousel v-bind="settings" v-if="responseForFE.length"> 
                <div class="logo-home" v-for="(logo_client, index) in responseForFE" :value="index" :key="logo_client.id">
                         <img :src="url.concat(logo_client.img_logo.url)" alt="">
                </div>
                <!-- <div class="logo-home">
                    <img src="../assets/img/logo/bjb.png" alt="">
                </div> -->
        </VueSlickCarousel>
    </div>
    <Footer/>
    <router-view/>
  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import Header from '../components/Header'
import Footer from '../components/Footer'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

const axios = require('axios')
export default {
    name: 'Home',
    metaInfo () {
        return {
            title: this.metaTitle,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords }
            ]    
        }
    },
    data() {
        return {
            responseForFE: [],
            indicator: false,
            settings_project_slick: {
                "arrows": true,
                "infinite": true,
                "slidesToShow": 4,
                "dots": false,
                "responsive": [
                    {
                    "breakpoint": 769,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2,
                        "initialSlide": 1
                    }
                    },
                    {
                    "breakpoint": 480,
                    "settings": {
                        "slidesToShow": 1,
                        "slidesToScroll": 1
                    }
                    }
                ]
            },
            settings: {
                "arrows": true,
                "infinite": true,
                "slidesToShow": 6,
                "dots": false,
                "autoplay": true,
                "autoplaySpeed": 5000,
                "row": 1,
                "responsive": [
                    {
                    "breakpoint": 769,
                    "settings": {
                        "slidesToShow": 4,
                        "slidesToScroll": 1,
                        "initialSlide": 1
                    }
                    },
                    {
                    "breakpoint": 480,
                    "settings": {
                        "slidesToShow": 2,
                        "slidesToScroll": 2
                    }
                    }
                ]
            },
            carousels: [
                { text: 'TUJUH DECOR', link_url: 'https://facebook.com', description_slider: 'Menyediakan semua kebutuhan dekorasi bangunan dan rumah anda Motif dan bentuk bisa by request-1.', color: 'primary' },
                { text: 'TUJUH DECOR', link_url: 'https://facebook.com', description_slider: 'Menyediakan semua kebutuhan dekorasi bangunan dan rumah anda Motif dan bentuk bisa by request-2.', color: 'info' }
            ],
            interval: 9000,
            homepage: {},
            url: process.env.VUE_APP_STRAPI_URL,
            responseProduct: [],
            metaTitle: null,
            metaDescription: null,
            keywords: null,
            pauseInfo: false,
        }
    },
    components: {
        VueSlickCarousel,
        Header,
        Footer
    },
    created() {
        axios.get(`${this.url}/homepage`).then(response => {
            this.homepage = response.data
            this.metaTitle = this.homepage.seo.metaTitle
            this.metaDescription = this.homepage.seo.metaDescription
            this.keywords = this.homepage.seo.keywords
        })
        axios.get(`${this.url}/logo-clients`).then(responseLogoClient => {
            this.responseForFE = responseLogoClient.data
        })
        axios.get(`${this.url}/products?_start=0&_limit=8`).then(responseProduct => {
            this.responseProduct = responseProduct.data
        })
    },
    mounted(){

    }
}
</script>
