<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import jQuery from 'jquery'
const axios = require('axios')
export default {
  name: 'App',
  metaInfo: {
      // override the parent template and just use the above title only
      titleTemplate: null
  },
  data() {
      return {
            responseHeader: {},
            favicon: "",
            url_domain: process.env.VUE_APP_STRAPI_URL,
            for_change_domain: null
      }
  },
  created(){
      
  },
  mounted() {
    
    jQuery( document ).ready(function() {
      var favicon = document.getElementById('favicon');
      axios.get(`${process.env.VUE_APP_STRAPI_URL}/header`).then(response => {
        this.responseHeader = response.data
        this.favicon = this.domain.concat(this.responseHeader.favicon.url)
        this.url_domain = process.env.VUE_APP_STRAPI_URL
        this.for_change_domain = this.url_domain.concat(this.responseHeader.favicon.url)
        favicon.href = this.for_change_domain 
      });
    })
        
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  text-align: center;
}

/* general */
@font-face {
    font-family: "Open Sans";
    src: url('assets/font/Open_Sans/static/OpenSans/OpenSans-Regular.ttf');
}
@font-face {
    font-family: "Karla";
    src: url('assets/font/Karla/static/Karla-Regular.ttf');
}
@font-face {
    font-family: "Spectral";
    src: url('assets/font/Spectral/Spectral-Regular.ttf');
}
body {
  scroll-behavior: smooth;
}
.container.fullwidth {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.p-10 {
  padding-bottom: 10px !important;
}
.p-25 {
  padding-bottom: 25px !important;
}
.p-50 {
  padding-bottom: 50px !important;
}
.m-25 {
  margin-bottom: 25px !important;
}
.m-50 {
  margin-bottom: 50px !important;
}
.m-100 {
  margin-bottom: 100px !important;
}
.title.is-1 {
    font-weight: 700;
    color: #142E70;
}
.f-karla {
    font-family: "Karla";
}
.f-spectral {
    font-family: "Spectral";
}
.f-open-sans {
    font-family: "Open Sans";
}
.title-gold {
    color: #D6C09F;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.06px;
}
.title-white {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #FFFFFF !important;
}
.mg-min-185 {
  margin-top: -185px !important;
  position: relative;
}
.mg-185 {
  margin-top: 185px !important;
  margin-bottom: 135px !important;
  position: relative;
}
h1.title.is-1.f-spectral {
  font-weight: bold;
  font-size: 56px;
  line-height: 85.23px;
  letter-spacing: normal;
  color: #142E70;
}
#navbar-menu {
  transition: all ease-in-out .2s;
}
.text-not-found {
  text-align: left;
  color: #0A183B;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.06px;
  margin-bottom: 75px;
}
button {
  cursor: pointer;
}
.help.is-danger {
  text-align: left !important;
}

/* header */
.bar {
  background: #0A183B;
  color: #D6C09F;
  padding: 15px 0px 14px;
}
.bar p {
  /* padding: 17px 0px 0px; */
}
.bar .nav-social-menu a{
  color: #D6C09F !important;
}
.f-left, .f-right {
  font-size: 16px;
}
.f-left {
  text-align: left;
  margin-left: 25px;
}
.f-left div {
  display: inline-block;
  margin-left: 4px;
  position: absolute;
  top: 9px;
}
.f-left.fixed, .f-right.fixed {
  /* padding: 30px 0px 15px; */
  padding: 27.5px 0px 15px;
}
.f-left.fixed div{
  padding-top: 4px;
}
.f-left div{
  color: #142E70 !important;
}
.bar .f-left div {
  color: #D6C09F !important;
}
.icon-header {
  max-height: 18px;
}
.navbar-menu {
  position: fixed;
  z-index: 9999;
  width: 100%;
  background: #fff;
  /* box-shadow:  0px 1px 10px rgba(0, 0, 0, 0.25); */
}
#navbar-menu {
  top: 138px;
}
.nav-menu {
  display: flex;
  padding: 10px 0 15px; 
  justify-content: center;
  margin: 0px 16px;
}
.menuActive a{
  color: #142E70 !important;
  font-weight: bold !important;
  border-bottom: 2px solid #D6C09F;
}
.nav-menu li a{
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  margin: 0px 15px;
  color: #000;
  font-family: "Open Sans";
}
span#sub-menu {
  transition: all ease-in-out .2s;
}
.nav-menu li#sub-menu:hover ul#sub-menu{
  display: block;
  position: absolute;
  text-align: left;
  background: #fff;
  border: 1px solid #BDBDBD;
  padding: 9px 0px;
  top: 40px;
  width: 185px;
}
ul#sub-menu li {
  position: relative;
  padding: 6px 0px;
  height: 40px;
}
ul#sub-menu li { 
  width: 100% !important;
}
ul#sub-menu li a{ 
  font-size: 16px !important; 
  color: #828282 !important;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
}
ul#sub-menu li:hover a{ 
  color: #D6C09F !important;
}
ul#sub-menu li:hover ul#submenu-submenu li a{ 
  color: #828282 !important;
}
ul#sub-menu li:hover ul#submenu-submenu li:hover a{ 
  color: #D6C09F !important;
}
ul#sub-menu li a span{ 
  float: right;
  position: absolute;
  right: 12px;
  top: 6px;
  font-weight: 700;
}
li#submenu-submenu:hover ul#submenu-submenu{
  display: block;
  position: absolute;
  text-align: left;
  background: #fff;
  border: 1px solid #BDBDBD;
  padding: 9px 0px;
  left: 184px;
  top: -10px;
  width: 227px;
}
ul#submenu-submenu:hover ul#submenu-submenu{
  display: block;
}
.header-mobile {
  display: none;
}
.columns.is-mobile.menu-mobile {
  height: 100px;
}
.nav-social-menu {
  display: flex; 
  float: right;
  margin-right: 15px;
}
.nav-social-menu li a{
  padding: 0px 9px;
  color: #000000 !important;
}
.nav-menu li ul{
  display: none;
  padding: 9px;
}
.navbar-logo {
  margin: 25px 0px 20px!important;
  max-height: 45px;
}

/* slider */
.carousel-arrow .icon.has-icons-left, .carousel-arrow .icon.has-icons-right {
    top: 50% !important;
}
.is-bg-banner img.img-banner {
  min-height: 444px;
  object-fit: cover;
}

.carousel-arrow.is-hovered span.icon.has-icons-left {
    left: 57px;
  } 
.carousel-arrow.is-hovered span.icon.has-icons-right {
    right: 52px ;
  }
.carousel-indicator.is-inside.is-bottom {
  display: none;
}
i.mdi.mdi-chevron-left.mdi-24px {
  background: url('assets/img/vector-left.png');
  background-size: contain;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}
i.mdi.mdi-chevron-right.mdi-24px {
  background: url('assets/img/vector-right.png');
  background-size: contain;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}
span.icon {
  background: transparent !important;;
}
span.icon:hover {
  border: 0px !important;
}
.carousel-arrow.is-hovered {
  opacity: 1 !important;
}
.is-bg-banner {
  background: #F5F5F5 !important;
}
.is-section-1 {
  margin-top: 50px;
}
.hero-body {
  text-align: left;
}
.hero-body .title-banner{
  font-family: "Spectral";
  font-weight: bold;
  font-size: 78px;
  color: #0A183B !important;
  line-height: 119px;
}
.hero-body .desc-banner {
  font-size: 24px;
  color: #464646;
  line-height: 38px;
}
.hero-body img.img-banner {
  float: right;
}
.hero-body a {
  color: #D6C09F;
}
.button-general {
  position: relative;
  padding: 17.5px 35px;
  margin-top: 50px;
}
.button-general .button-1 {
  border: 2px solid #D6C09F;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
}
.button-general .button-2 {
  border: 2px solid #D6C09F;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 0;
}
.button-general span.text{
  font-weight: 700;
  font-size: 16px;
}
.button-general:hover {
  background: #0A183B;
  color:#D6C09F;
  z-index: 999999999;
  transition: all ease-in .3s;
}
.button-general:hover .button-2 {
  z-index: -9;
}

@media screen and (min-width: 769px), print {
  .hero.is-medium .hero-body {
    padding: 9rem 14rem !important;
  }
}

/* product unggulan */
.section-home-2 {
  /* padding: 22.5px ; */
  margin: 5px 99px !important;
}
.section-home-2 .title{
  color: #58585A;
  font-weight: 700;
  font-size: 28px !important;
  line-height: 32.73px;
}
.wrap-img {
  position: relative;
  height: 396px;
  background: #F4F4F4;
  padding-top: 17.5%;
}
.wrap-img:hover {
  border: 1px solid #D6C09F;
}
.wrap-img img {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
}
.overlay {
  visibility: hidden;
  display: flex;
  justify-content: center;
  background: #ffffffc7;
  z-index: 999;
  height: 396px;
}
.overlay a {
  top: 40%;
  position: absolute;
  padding: 12px 34px;
  color: #D6C09F;
  transition: all ease-in .3s;
  font-weight: 600;
}
.overlay a:hover {
  top: 40%;
  background: #0A183B;
  position: absolute;
  padding: 12px 34px;
  color:  #D6C09F;
  font-weight: 700;
}
.button-1 {
  border: 2px solid #D6C09F;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
}
.button-2 {
  border: 2px solid #D6C09F;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: -1;
}
.wrap-img:hover .overlay{
  position: absolute;
  visibility: visible;
  display: flex;
  justify-content: center;
  background: rgba(244, 244, 244, 0.97);
  z-index: 999;
  top: 0;
  height: 394px;
  width: 100%;
}
.text {
  font-size: 16px;
}
.title-product {
  margin-top: 25px;
}

/* why 7 decor */
.bg-gradient {
    background: linear-gradient(114.62deg, #DFDFDF -196.45%, #091738 105.43%);
    text-align: left;
    color: #fff !important;
    padding: 75px 130px !important;
}
.bg-gradient h2, .bg-gradient h6{
    color: #fff !important;
}
.why {
  padding-bottom: 27px;
}
.bg-why {
  /* background: url('assets/img/why-min.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  display: flex !important;
  padding: 0rem 0rem !important;
}
.bg-why img{
  object-fit: cover;
}
.title-custom {
  font-weight: bold;
  font-size: 56px;
  line-height: 82px;
  color: #FFFFFF;
}
.subtitle-why {
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: #FFFFFF;
  padding-left: 4px;
  padding-bottom: 11px;
}
.content-why {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.6);
  padding-left: 32.5px;
}
.title-why {
    display: grid;
    grid-template-columns: 6% 90%;
}
/* .title-why div {
  width: 48.5px;
}
.img-why-top {
  width: 24.5px !important;
}
.img-why-bottom {
  width: 36.5px !important;
} */
.title-why img {
  width: 19px !important;
  height: 19px !important;
}

/* testimonial */
.bg-testimonials {
  background-image: url('assets/img/bg-testimonials-min.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 0px;
  min-height: 450px;
  position: relative;
}
.box-testimonial {
  position: relative;
  /* height: 386px; */
  /* display: flex; */
  margin: 8px;
}
.box-testimonial img{
  width: 100%;
  height: auto;
}
.overlay-testimonials {
  visibility: hidden;
  top: 0;
  left: 0;
  position: absolute;
}
.box-testimonial:hover .overlay-testimonials {
  position: absolute;
  visibility: visible;
  top: 0;
  left: 0;    
  background: #ffffffc7;
  /* height: 100%; */
  height: 100%;
  width: 100%;
  text-align: left;
  padding: 95px 35px ;
  border: 2px solid #D6C09F;
}
.name {
  font-weight: bold;
  font-size: 24px;
  color: #142E70;
}
.position {
  font-size: 18px;
  line-height: 21px;
  color: #382933;
}
.overlay-testimonials p {
  font-size: 14.559px;
  line-height: 23px;
  padding-top: 25px;
  color: #382933;
}

/* logo */
.logo-slick-home {
  margin-top: 85px !important;
}
.logo-slick-home .slick-initialized .slick-slide {
  padding: 0px 25px;
}
.slick-prev:before {
  background: url('assets/img/arrow-left-logo.png');
  background-size: contain;
  width: 21px;
  height: 34px;
  background-repeat: no-repeat;
}
.slick-prev {
  left: -2px !important;
}
.slick-next {
  right: -2px !important;
}
.slick-next:before {
  background: url('assets/img/arrow-right-logo.png');
  background-size: contain;
  width: 21px;
  height: 34px;
  background-repeat: no-repeat;
}
.slick-prev:before, .slick-next:before {
  color: transparent !important;
}
.logo-home {
  display: flex !important;
  justify-content: center;
}
.slick-next, .slick-prev {
  width: 21px !important;
  height: 34px !important;
  z-index: 999 !important;
}
.slick-prev:before, .slick-next:before {
  font-size: 34px !important;
}
.logo-home {
  height: 100px;
  position: relative;
  display: flex !important;
}
.logo-home img{
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
}

/* about-- */
.title-page {
  height: 300px;
  color: #0A183B;
  text-transform: uppercase;
  padding: 75px;
  position: relative;
  background-position: 82% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.profile-title {
  background:url('assets/img/Profile-Bg-Asset.png');
  background-position: 82% 50% !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.title-logo-profile {
  line-height: 72.91px !important;
}
.section-1-profile, .section-3-profile {
  padding: 125px 0px;
  text-align: left;
}
.section-1-profile p, .section-3-profile p {
  font-size: 24px;
  line-height: 38px;
  color: #464646;
}
.section-2-profile {
  /* background-image: url('assets/img/ProfileBgBlue.png'); */
  padding: 125px 0px;
  background-repeat: no-repeat;
  background-size: cover;       
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
  color: #FFFFFF;
}
.img-left {
  margin-right: 72px;
}
.img-right {
  margin-left: 72px;
}
.section-4-profile {
  padding: 25px 0px 25px;
}
.box-count span {
  font-weight: 700;
}
.desc-count {
  font-size: 28px;
  line-height: 32.73px;
}
.logo-profile {
  display: flex !important;
  /* justify-content: center; */
  /* margin-bottom: 75px; */
  height: 100px;
  position: relative;
}
.logo-profile img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lsp2 {
  border: 2px solid #E0E0E0 !important;
  padding: 60px 60px 130px;
  margin: 50px 115px 90px;
}
.logo-profile img, .logo-home img {
  filter: grayscale(100%);
}
.logo-profile img:hover, .logo-home img:hover{
  filter: grayscale(0%);
}
.slick-dots li.slick-active button:before {
  color: #D6C09F !important;
}
.slick-dots li button:before {
  color: #C4C4C4 !important
}
.slick-dots li button:before {
  font-size: 16px !important;
}
.slick-dots {
  bottom: -60px !important;
}

/* contact-- */
.contact-title {
  /* background:url('assets/img/contact-bg.png'); */
}
/* .grecaptcha-badge {
   display: none !important;
 } */
.success-message {
  border: 2px solid #dbdbdb;
  color: #142E70;
  padding: 4px;
}
.section-2-footer  {
  font-size: 24px;
  text-align: left;
  padding: 125px 0px;
}
.section-2-footer a , .section-2-footer p span, .section-2-footer p{
  color: #464646 !important;
  line-height: 38px !important;
}
.sosmed-contact ul {
  display: flex;
  margin-top: 26px;
}
.sosmed-contact ul li a{
  cursor: pointer;
}
.sosmed-contact ul li{
  font-size: 22px;
  margin-right: 25px;
  padding: 7.5px 10px;
}
.sosmed-contact ul li img{
  filter: invert(56%) sepia(0%) saturate(0%) hue-rotate(151deg) brightness(91%) contrast(81%) !important;
}
.sosmed-contact ul li:hover img{
  filter: none !important;
}
.sosmed-contact ul li a{
  color: #828282 !important;
}
.section-2-footer  {
  padding: 125px 0px;
}
iframe{
  width: 85%;
}
#contact-form input.input {
  height: 79px !important;
}
.column-center {
    padding: 0px 42.5px !important;
    margin-bottom: 25px;
}
#contact-form input.input, #contact-form textarea.textarea{
  border-radius: 0px !important;
  border: 2px solid #BDBDBD !important;
  font-size: 20px !important;
}
#contact-form textarea.textarea {
   height: 425px;
}
#contact-form button{ 
  background: #0A183B !important;
  border-radius: 0px !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-family: "Karla";
  align-items: center;
  text-align: center;
  color: #D6C09F;
  padding: 12px 50px !important;
}


/* detail product-- */
.lightbox .lb-nav {
    top: 100% !important;
    height: 13.5%;
    background: #fff;
}
.wrap-img-detail img{
    width: 100%;
    height: 548px;
    position: relative;
    object-fit: cover;
}
.lb-nav a.lb-next {
    float: none !important;
}
.lightbox .lb-nav a.lb-prev, .lightbox .lb-nav a.lb-next{
    opacity: 1;
}
.lightbox a.lb-prev,
.lightbox a.lb-next {
    display: block !important;
}
.lightbox .lb-number {
    z-index: 999 !important;
    position: relative;
    font-size: 20px;
    font-family: "Karla";
    text-align: center;
    padding-top: 12px;
}
.lightbox .lb-data .lb-details {
    width: 100%;
}
.lightbox .lb-nav {
    padding-left: 33px !important;
}
.lightbox .lb-nav a.lb-next, .lb-nav a.lb-prev {
    width: 10% !important;
}
.lightbox {
  position: fixed !important;
  top: 50px !important;
}
.lb-nav a.lb-prev, .lb-nav a.lb-next{
    opacity: 1 !important;
    display: block !important;
}
.lb-closeContainer {
    position: absolute !important;
    top: -30px !important;
    right: 15% !important;
}
.lb-data .lb-details {
        width: 100% !important;
}
.lb-data .lb-number {
    text-align: center !important;
    font-size: 20px !important;
    font-family: "Karla" !important;
    padding-top: 1.5em !important;
    padding-bottom: 0.5em !important;
    background: transparent !important;
    width: 50%;
    margin: 0px 25%;
}
.lb-data {
    padding: 0px !important;
}
.lb-dataContainer {
    padding-top: 0px !important;
}

.wrap-title-product {
  background: #F5F5F5;
  /* height: 300px; */
  color: #0A183B;
  /* padding: 105px 0px 65px; */
  position: relative;
  height: 100%;
  width: 100%;
  vertical-align: middle;
  /* padding: 77.5px 0px 77.5px; */
}
.title-product-detail {
  font-weight: bold;
  font-size: 56px;
  text-align: left;
  text-transform: uppercase !important;
  color: #142E70;
}
 .title-gallery{
  font-weight: bold;
  font-size: 56px;
  text-align: left;
  text-transform: uppercase !important;
  color: #0A183B;
 }
.breadcumb {
  font-weight: bold;
  font-size: 24px;
  text-align: right;
  text-transform: uppercase;
}
.breadcumb a {
  color: #0A183B !important;
}
.column-breadcumb {
  margin: auto !important;
}
.section-2-product {
  padding: 75px 0px !important;
  min-height: 85vh;
}
/* .section-2-product-detail .column:nth-child(1){
  padding: 0px 120px 0px 60px !important;
} */
.section-2-product-detail, .section-1-product {
  padding: 75px 125px !important;
}
.is-fluid.section-1-product-list {
  padding: 75px 125px !important;
}
.color-material select {
  color: #828282;
  font-size: 16px;
  line-height: 24px;
  background: transparent;
}
.gallery-product {
  position: sticky !important;
}
.column-desc-product {
  text-align: left;
  position: sticky;
  position: -webkit-sticky;
  padding-bottom: 55px;
}
.color-material select {
  margin-right: 9px;
  border-radius: 0px !important;
  padding: 6px 12px 6px 6px !important;
  border: 2px solid #BDBDBD !important;
}
.desc-product {
  padding-bottom: 35px;
  color:  #464646;
  font-size: 24px;
  line-height: 38px;
}
.sku-category {
  padding-bottom: 45px;
  color:  #464646;
  font-size: 24px;
  line-height: 38px;
}
.wrap-img-detail {
  background: #F4F4F4;
  margin-bottom: 55px;
  max-width: 707px;
  width: auto;
  /* height: 548px; */
  position: relative;
}
/* .wrap-img-detail img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

/* product list-- */
.is-fluid.list-product {
  padding: 0px 75px !important;
}
.name-product-list {
  font-weight: bold;
  font-size: 48px;
  line-height: 73px;
  color: #142E70;
  text-align: left;
}
.cat-product-list {
  font-weight: bold;
  font-size: 32px;
  line-height: 37px;
  color: #D6C09F;
  text-align: left;
}
.wrap-img-product-list {
  height: 535px;
  background: #F4F4F4;
  padding: 65px;
}
.wrap-img-product-list img {
  transition: transform 0.8s; 
  max-height: 425px;
}
.wrap-img-product-list img:hover{
  transform: scale(1.1);
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
}
.box-product-list{
  margin-bottom: 75px;
}

/* faq-- */
.faq-title {
  /* background: url('assets/img/faq-bg.png'); */
}
.section-2-faq {
  padding: 125px 112.5px !important;
}
.card-header {
  background: #F5F5F5 !important;
  padding: 30px;
  font-weight: bold;
  font-size: 32px;
  line-height: 49px;
}
.normal-header {
  background: #F5F5F5 !important;
}
.card-header {
  padding: 13.5px !important;
}
.card-header:hover {
  background: #142E70 !important;
}
.card-header svg{
  color: #142E70 !important;
}.card-header:hover svg{
  color: #d6c09f !important;
}
.collapse.card {
  margin-bottom: 44px;
}
p.card-header-title.f-spectral {
  color: #142E70 !important;
  font-size: 28px;
}
.card-header:hover p.card-header-title.f-spectral{
  color: #d6c09f !important;
}
.card-content {
  text-align: left !important;
  font-size: 20px;
  line-height: 30px;
}
.card {
  box-shadow: 0px 0px 0px !important;
}

.wrap-title-faq {
  padding: 75px 0px 50px;
  text-align: left;
}
.title-faq {
  color: #142E70 !important;
}
.active-header {
  background: #142E70 !important;
}
.active-header p.card-header-title.f-spectral {
  color: #d6c09f !important;
}
.active-header svg{
  color: #d6c09f !important;
}

/* gallery-- */
.section-1-gallery {
  padding: 75px 125px !important;;
}
.section-2-gallery {
  padding: 0px 135px 0px 132px !important;
}
.column.wrap-img-gallery {
    padding: 0px !important;
    margin: 14px !important;
    min-height: 527px;
}
.column.wrap-img-gallery img {
    min-height: 527px;
    width: auto;
    object-fit: cover;
}
.filter {
  text-align: right;
  padding: 25px;
}
.container-gallery {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}
.wrap-img-gallery {
  position: relative !important;
}
.wrap-img-gallery:hover .white-hover{
  visibility: visible;
}
.white-hover {
  position: absolute;
  background: rgba(244, 244, 244, 0.95);
  top: 0;
  left: 0;
  visibility: hidden;
  height: 99%;
  width: 100%;
  border: 2px solid #D6C09F;
}
.white-hover h1 {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%);
  color: #142E70 !important;
}
.button-view-more {
  background: transparent;
  border: 1px solid #D6C09F;
  margin: 47.5px 75px 70px;
  font-size: 16px;
  padding: 9px 36px;
  color: #D6C09F;
  transition: all ease-in .3s;
  border-radius: 0px !important;
}
.button-view-more:hover {
  background: #142E70;
}
.text-not-found {
  text-align: left;
  font-size: 24px;
}
.is-text {
  text-decoration: none !important;
}
.filter-wrap {
  position: relative;
  width: 40%;
  float: right;
  margin-right: 17.5px;
}
.filter-button {
  width: 100%;
  border: 1px solid #CED4DA;
  display: flex;
  justify-content: space-between;
  padding: 18px 14px;
  margin: 25px 0rem;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #54595E;
}
label.b-checkbox.checkbox.button {
    color: #828282;
}
button.button.is-link, button.button.is-light {
    padding-left: 1.5em;
    padding-right: 1.5em;
}
.filter-trigger {
    position: absolute;
    width: 100%;
    height: auto;
    top: 85px;
    /* left: 0.75rem; */
    z-index: 99;
    text-align: left;
    background: #FFFFFF;
    box-shadow:  0px 1px 10px rgba(0, 0, 0, 0.25);
}
.filter-trigger p {
  font-weight: bold;
  font-size: 16px;
  padding: 0.75rem  14px;
}
.filter-trigger form {
  padding: 0px 6px;
}
.input-checkbox {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.filter-trigger .control {
      margin: 0.50rem  0.25rem !important;
}
.filter-trigger input{
    width: max-content;
    text-align: center;
}
.filter-trigger label.b-checkbox.checkbox.button, .footer-filter button {
    border-radius: 0px !important;
    font-size: 12px !important;
    font-weight: bold !important;
    font-family: "Karla" !important;
}
.button.is-fullwidth {
    justify-content: space-between;
}
.footer-filter {
    margin: 0.35rem 0.25rem;
}
.footer-filter button {
    margin: 0.50rem  0.25rem !important;
}
.button.is-link, .button.is-primary  {
    background: #142E70 !important;
    color: #D6C09F !important;
}
.others-project {
    text-align: left !important;
}
.other-projects-wrap {
    margin-bottom: 60px !important;
}

/* footer */
.title-footer {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 26px;
  font-weight: 700;
}
footer {
  background: #0A183B;
  padding: 58px 58px 26px;
  color: #D6C09F;
  text-align: left;
  font-size: 16px;
}
footer hr {
  background: #D6C09F;
  height: 1px !important;
}
.logo-footer {
  margin-bottom: 14px;
}
.desc-footer {
  margin: 0px 14px 0px 0px;
}
.contact-footer {
  margin-bottom: 26px;
}
.contact-footer a{
  color: #D6C09F !important;
}
.wrap-contact-footer {
  display: grid;
  grid-template-columns: 10% 90%;
}
.img-contact-footer {
  margin-top: 4px;
}
.o-hours {
  line-height: 25px;
}
.o-hours p {
  line-height: 26px;
  margin-bottom: 26px;
}
.menu ul li {
  margin-bottom: 20px;
}
.menu ul li a {
  color: #D6C09F !important;
}
.form-subcribe {
  /* display: flex !important; */
  display: grid;
  grid-template-columns: 60% 40%;
}
.form-subcribe input {
  border-radius: 0px !important;
  font-weight: 500;
  font-size: 16px;
}
.form-subcribe button {
  background-color: #E7BD7A !important;
  border-radius: 0px !important;
  color: #142E70 !important;
  font-weight: 500;
  font-size: 14px;
  border: 0px;
  padding: 0px 9px;
  height: 40px;
  margin-left: 10%;
  /* width: 40%; */
  cursor: pointer;
}
.sosmed-footer ul {
  display: flex;
  margin-top: 26px;
}
.sosmed-footer ul li{
  margin-right: 16px;
}
.notif-subscribe {
  border: 2px solid;
  padding: 5px;
}
.copyright {
  text-align: center;
}
.title-page h1.title.is-1.f-spectral, .section-1-gallery .title-product-detail, .section-1-product h1{
  color: #0A183B !important;
}
footer .columns.columns-tablet .column:nth-child(2), footer .columns.columns-tablet .column:nth-child(3) {
  padding-left: 55px;
}
ul#sub-menu li a, ul#submenu-submenu li a{
  border-bottom: 0px solid transparent !important;
}
ul#sub-menu li a.router-link-exact-active.router-link-active,ul#submenu-submenu li a.router-link-exact-active.router-link-active {
  color: #D6C09F !important;
}


@media screen and (max-width: 1441px) {
  .nav-menu li ul {
    margin-top: 0px;
  }
  .nav-menu li a {
      margin: 0 31px !important;
      padding: 0px 0px;
      color: #333;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 0px;
  }
  ul#sub-menu li {
      height: 32px;
  }
  ul#sub-menu li a,ul#submenu-submenu li a {
      font-size: 11px !important;
      font-weight: 400;
  }
  .nav-menu li#sub-menu:hover ul#sub-menu {
      top: 36.5px !important;
  }
  .is-section-1 {
      margin-top: 38px !important;
  }
  .input-checkbox {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .filter-wrap {
    width: 45%;
    margin-right: 5px;
  }
  .wrap-img-detail {
    width: 100%;
  }
  .wrap-img-detail img{
    width: 100%;
    height: 448px;
    position: relative;
    object-fit: cover;
  }
  .column.wrap-img-gallery img {
    min-height: 475px;
  }
  .white-hover h1 {
    top: 45%;
    left: 45%;
    transform: translate(-45%, -45%);
  }
  #gallery-product {
    padding-right: 60px;
  }
  S
  .content-why {
    padding-left: 22.5px;
  }
  .nav-menu {
    padding: 12px 0px 0px;
    height: 46px;
  }
  .nav-menu li ul{
    margin-top: 0px;
  }
  .navbar-logo {
    margin: 30px 0px 20px !important;
  }
}

@media screen and (max-width: 1367px) {
  .section-1-profile, .section-3-profile {
    padding: 125px 0px 115px;
  }
  .section-4-profile {
    padding-bottom: 90px;
  }
  .lsp2 {
    margin: 0px 115px 130px;
    padding: 60px 60px 120px !important;
  }
  .section-2-faq h1.title.is-1.f-spectral {
    padding: 0px 0px;
    margin: 22px 0 0px;
  }
  .collapse.card:nth-child(1) {
    margin-top: 0px;
  }
  .section-home-1 h1.title.is-1.f-spectral{
    padding: 0px 0 0px;
    margin: 0px 0px 16px;
  }
  .section-home-2 {
    padding-top: 38.5px !important;
    padding-bottom: 90px !important;
    /* padding-top: 77px !important; */
    /* padding-top: 114px !important; */
  }
  .content-why {
    padding-left: 40px;
  }
}

/* Widescreen */
@media screen and (max-width: 1407px) {
  .nav-menu li ul {
    margin-top: 0px;
  }
  .nav-menu li a {
      margin: 0 31px !important;
      padding: 0px 0px;
      color: #333;
      font-size: 13px;
      font-weight: 600;
      margin: 0px 0px;
  }
  ul#sub-menu li a,ul#submenu-submenu li a {
      font-size: 11px !important;
      font-weight: 400;
  }
  .nav-menu li#sub-menu:hover ul#sub-menu {
      top: 42.5px;
  }

  /* profile */
  h1.title.is-1.f-spectral {
      font-size: 40px;
      line-height: 1.275em;
      padding: 22px 0 16px;
  }
  .section-1-profile h1.title.is-1, .section-3-profile h1.title.is-1, .title-logo-profile {
      font-size: 34px;
      line-height: 1.275em;
      margin: 20px 0 0;
      padding: 0 0px;
  }
  .section-1-profile p, .section-3-profile p {
      line-height: 27px;
  }
  .box-count span {
      font-size: 60px;   
      line-height: 1em; 
  }
  .desc-count {
      font-size: 22px;  
      margin: 5px 0 0;
  }
  .section-4-profile .title-gold {
      font-size: 24px;  
      margin: 20px 0 4px;
  }
  .section-4-profile img {
      margin: 0px 0 10px;
  }
  .section-4-profile h1.title.is-1.f-spectral {
      padding: 0px 0px;
      margin: 0px 0 16px;
  }
  .lsp2 {
      padding: 0px 0px;
  }

  /* contact */
  .section-2-footer h1.title.is-1.f-spectral, .section-3-footer h1.title.is-1.f-spectral  {
      margin: 20px 0 0;
      padding: 0 0px;
      font-size: 34px;
  }
  .section-2-footer a, .section-2-footer p span, .section-2-footer p {
      font-size: 14px;
      line-height: 27px;
  }
  iframe {
    width: 100%;
    height: 451px;
  }
  .section-2-footer .column.is-two-fifths:nth-child(3) {
    padding: 0px 0px 0px 29px;
  }

  #contact-form {
    margin-top: 15px;
  }
  #contact-form input.input, #contact-form textarea.textarea {
    padding: 13px 21px;
    font-size: 12px !important;
  }
  .column-center {
    margin-bottom: 32px !important; 
  }
  .m-25 {
    margin-bottom: 8px !important; 
  }

  /* product list */
  .title-product-detail {
    font-size: 34px;
  }
  .breadcumb {
    font-size: 16px;
  }
  .wrap-title-product {
    position: relative;
    height: 100%;
    width: 100%;
    vertical-align: middle;
    /* padding: 77.5px 0px 77.5px; */
  }
  .name-product-list, .cat-product-list {
    font-size: 16px;
    line-height: inherit;
  }
  .wrap-desc-product-list {
    margin: 16px 0 28px;
  }
  .wrap-img-product-list {
    height: 309.7px;
    background: #F4F4F4;
    padding: 25px;
  }
  .wrap-img-product-list img {
    transition: transform 0.8s;
    max-height: 250px;
  }

  /* product detail  */
  /* .wrap-img-detail {
    width: 100%;
    height: 548px;
    position: relative;
  } */
  .lb-data .lb-number {
    padding-top: 0.75em !important;
  }
  #gallery-product { 
    padding-right: 60px;
  }

  /* header */
  .bar .columns{
    position: relative;
    height: 100%;
    width: 100%;
    vertical-align: middle;
  }
  .bar p {
    font-size: 14px;
  }
  .bar .f-left div {
    top: 12px;
  }
  .f-left div{
    color: #142E70 !important;
  }
  .bar .f-left div {
    color: #D6C09F !important;
  }
  .f-left {
    font-size: 12px;
  }
  .f-left div {
    top: 11px;
  }
  .nav-menu {
    /* padding: 18px 0px 15px; */
  }
  /* .navbar-menu {
    padding: 9px 0px 0px;
  } */
  #navbar-menu {
    top: 150px;
  }
  .nav-menu li ul li {
    width: 240px;
  }
  .f-left.fixed, .f-right.fixed {
    /* padding: 32.5px 0px 15px;  */
    padding: 27.5px 0px 15px;
  }
  /* .navbar-logo {
    margin: 30px 0px 20px !important;
  } */
  .nav-menu li ul {
    display: none;
  }
  .f-right {
    margin-right: 25px;
  }

  /* home */
  .is-section-1 {
    margin-top: 25px;
  }
  .hero-body .title-banner {
    font-size: 75px;
  }
  .hero-body .desc-banner {
    font-size: 18px;
  }
  .button-general span.text {
    font-size: 14px;
    letter-spacing: .1em;
  }
  .wrap-img {
    height: 309px;
  }
  .wrap-img:hover .overlay{
    height: 307px;
  }
  .section-home-2 .title {
    font-size: 16px !important;
    margin: 22px 0 38px;
  }
  .title-custom {
    font-size: 40px;
  }
  .subtitle-why {
    font-size: 22px;
    line-height: normal;
  }
  .content-why {
    font-size: 14px;
  }
  .bg-gradient {
    padding: 48px 12% 74px 100px !important;
  }
  .title-white {
    font-size: 24px;
    margin: 20px 0px 4px;
  }
  .bg-testimonials h1.title.is-1.f-spectral {
    margin: 0px 0px 16px !important;
    padding: 0px 0px;
  }
  .bg-testimonials .separator {
    margin: 0px 0px 10px !important;
  }
  .bg-testimonials {
    min-height: auto;
    padding-top: 114px !important;
    padding-bottom: 205px !important;
  }
  .slick-slide.slick-active {
    padding: 0px 15px;
  }
  .box-testimonial:hover .overlay-testimonials {
    padding: 35px 35px;
  }

  /* footer  */
  .desc-footer, footer, footer .menu ul li a {
    font-size: 14px;
  }
  .title-footer {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .form-subcribe button {
    margin-top: 0px;
    height: 40px;
  }
  .form-subcribe {
    /* display: flex !important; */
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .wrap-contact-footer {
    display: grid;
    grid-template-columns: 17.5% 82.5%;
  }

  /* product detail  */
  .desc-product, .sku-category, .color-material select {
    font-size: 14px;
  }

  /* gallery  */
  .title-gallery {
    font-size: 40px;
  }
  .filter-trigger label.b-checkbox.checkbox.button, .footer-filter button {
    font-size: 16px !important;
  }
  .section-2-gallery {
    padding: 0px 100px 0px 100px !important;
  }
  .filter-wrap {
    width: 65%;
    margin-right: 4px;
  }
  .filter-trigger {
    left: 1px !important;
  }
  .white-hover h1.title.is-1.f-spectral {
    font-size: 36px;
  }

  /* faq */
  .section-2-faq {
    padding: 105px 112.5px !important;
  }
  .wrap-title-faq {
    padding: 0px 0px 0px;
  }
  .toogle-faq {
    margin-top: 48px;
  }
  .section-2-faq h1.title.is-1.f-spectral {
    font-size: 34px;
  }
  p.card-header-title.f-spectral, .card-header-icon, .card-content {
    font-size: 14px !important;
  }
  .card-header-icon, .card-header-title {
    padding: 0px 1rem !important;
  }
  .card-header {
    padding: 16px 0px !important;
  }
  .collapse.card {
    margin-bottom: 0px;
    margin-top: 24px;
  }
  .card-header:hover svg {
    color: #142E70;
  }

  /* general */
  .title:not(:last-child) {
      margin-bottom: 0px !important;
  }
  .p-25 {
      padding-bottom: 0px !important;
      margin-bottom: 4px !important;
  }
  .p-50 {
      padding-bottom: 0px !important;
  }
  .mg-min-185 {
      margin-top: -115px !important;  
  }
  .separator {
    margin-top: 20px;
    margin-bottom: 19px !important;
  }

  .section-1-profile p, .section-3-profile p {
    margin-top: 10px;
    font-size: 14px;
  }

  /* home  */
  .section-home-2 {
    margin: 5px 74px !important;
  }
  .hero.is-medium .hero-body {
    padding: 9rem 12rem !important;
  }
  .title-why {
    grid-template-columns: 11% 89%;
  }
  .title-why img {
    margin-top: 6px;
  }
  .content-why {
    /* padding-left: 40px; */
  }
  .white-hover h1 {
    top: 45%;
    left: 45%;
    transform: translate(-45%, -45%);
  } 
  .white-hover {
    height: 98.5%;
  }

  /* product detail  */
  /* .column-desc-product {
    margin-bottom: 57.5px;
  } */
  .section-1-product .breadcumb {
    margin: 0px 0px;
  }
  .section-2-product-detail .title-product-detail {
    font-size: 34px;
  }

  /* gallery  */
  .breadcumb {
    margin: 22.5px 0px;
  }
  .input-checkbox {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .filter-trigger form {
    padding: 0px 12px;
  }
  .column.wrap-img-gallery img {
    min-height: 375px;
  }
  .column.wrap-img-gallery {
    min-height: 373px;
  }

  /* footer  */
  footer .columns.columns-tablet .column:nth-child(3), footer .columns.columns-tablet .column:nth-child(4) {
    padding-left: 60px;
  }
  .section-2-footer {
    font-size: 20px;    
  }
  #contact-form input.input {
    height: 62px !important;
  }
}


@media screen and (max-width: 1281px) {
  .content-why {
    padding-left: 37.5px;
  }
}

/* Desktop Between 1024px */
@media screen and (max-width: 1025px) {
  header {
    display: block;
  }
  .b-sidebar .sidebar-content {
    width: 100% !important;
    box-shadow: 0px 0px 0px !important;
  }
  .b-sidebar .sidebar-content.is-light {
    background: #ffffff !important;
  }
  .nav-menu-mobile ul li, .nav-menu-mobile li {
    padding: 9px 14.5%;
    text-align: left;
  }
  .nav-menu-mobile ul li a, .nav-menu-mobile li a{
    color: #0A183B;
    font-family: "Karla";
    font-size: 13px;
  }
  #sub-menu ul li a {
    font-size: 11px;
  }
  #sub-menu span.icon {
    float: right;
  }
  ul#submenu-submenu {
    display: none;
  }
  .show {
    display: block !important;
  }
  .show-list-product, .show-cat-product {
    display: block !important;
  }
}
@media screen and (max-width: 1023px) {
  header {
    display: none !important;
  }
  .header-mobile {
    display: block !important;
  }
  .is-section-1 {
    margin-top: 0px;
  }
  footer .columns.columns-tablet .column:nth-child(3), footer .columns.columns-tablet .column:nth-child(4) {
    padding-left: 0px;
  }
  footer .columns.columns-tablet .column:nth-child(2), footer .columns.columns-tablet .column:nth-child(3) {
    padding-left: 0px;   
  }
}

/* tablet  */
@media screen and (max-width: 769px) {
  .hero-body .title-banner {
    font-size: 70px;
  }
  .title-custom {
    font-size: 34px;
  }
  .section-2-product {
    padding: 0px 0px !important;
  }
  .section-1-product, .is-fluid.section-1-product-list {
    padding: 75px 15px!important;
  }
  .breadcumb {
    margin: 0px 0px;
  }
  .box-testimonial:hover .overlay-testimonials { 
    padding: 75px 35px;
  }
  .content-why {
    padding-left: 40px;
  }
  .hero.is-medium .hero-body {
    padding: 9rem 4rem !important;
  }
  .title-why {
    grid-template-columns: 6% 94%;
  }
  .is-section-1 {
    margin-top: 18px;
  }
  .img-left {
    margin-right: 0px;
  }
  .img-right {
    margin-left: 0px;
  }
  .lsp2 {
    border: 2px solid #E0E0E0 !important;
    padding: 60px 30px;
  }
  .hero-body .desc-banner {
    font-size: 18px;
    line-height: 28px;
  }
  .content-footer .menu ul li a {
    color: #D6C09F !important;
  }
  .is-custom-mobile ul li svg {
    float: right;
  }
  .menu-list li ul {
    border-left: 1px solid transparent !important;
  }
  .is-bg-banner {
    margin-top: 0px;
  }
  .logo-slick-home {
    padding: 0px 35px;
  }
  .navbar-logo {
    display: none;
  }
  .header-mobile {
    display: block;
    position: relative;
    padding: 18px 12px;
    height: 102px;
  }
  .logo-mobile {
    max-height: 65px;
  }
  #menu-hidden {
    width: 100%;
    position: absolute;
    top: 95px;
    left: 0;
    background: #FFFFFF;
    height: auto;
    z-index: 999;
  }
  #bars-mobile{
    padding: 0px 15px;
    text-align: left;
  }
  .bg-gradient {
      padding: 75px 80px !important;
  }
  a#sub-menu {
    width: 100%;
  }
  li span.icon {
    float: right;
  }
  .nav-menu-mobile ul li, .nav-menu-mobile li {
    padding: 9px 3.8rem;
    text-align: left;
  }
  .nav-menu-mobile ul li a, .nav-menu-mobile li a{
    color: #0A183B;
    font-family: "Karla";
    font-size: 13px;
  }
  #sub-menu ul li a {
    font-size: 11px;
  }
  ul#submenu-submenu {
    display: none;
  }
  .show {
    display: block !important;
  }
  .show-list-product, .show-cat-product {
    display: block !important;
  }
  .bar {
    display: none;
  }
  .title-page {
    background-position: 80% 50%;
  }
  .wrap-img {
    padding-top: 25%;
  }
  .p-mobile {
    padding: 50px 27px;
  }
  .bg-why {
    min-height: 500px;
  }
  .p-testimonials {
    padding: 0px 58px;
  }
  .slick-prev:before {
    font-size: 25px !important;
  }
  .slick-next:before {
    font-size: 25px !important;
  }
  .slick-prev, .slick-next {
    top: 50% !important;
  }
  .section-4-profile {
    padding-bottom: 25px;
  }
  iframe{
    width: 100%;
  }
  #contact-form {
    padding: 0px 25px;
  }
  #contact-form textarea.textarea {
    height: 225px;
  }
  .section-1-product, .breadcumb {
    text-align: center;
  }
  .section-2-product, .section-2-product-detail {
    padding: 75px 25px !important;
  }
  .column-desc-product {
    position: relative;
  }
  p.card-header-title.f-spectral {
    color: #D6C09F !important;
    font-size: 18px;
    text-align: left;
    line-height: normal;
  }
  .card-content {
    text-align: left !important;
    font-size: 14px;
  }
  .card-header  {
      padding: 10px;
  }
  .card-header-icon {
    padding: 0.75rem 0rem;
  }
  .card-header .svg-inline--fa {
    font-size: 18px;
  }
  .container-gallery {
    display: grid;
    grid-template-columns: auto auto;
  }
  .lb-closeContainer {
    top: -5%;
    position: absolute;
    left: 98% !important;
  }
  .filter-wrap {
    position: relative;
    width: 100% !important;
    float: none !important;
  }
  /* .filter-button {
    margin: 25px 0.50rem !important;
  } */
  .filter-trigger { 
    /* left: 0.50rem; */
    top: 65px;
  }
  #column-desc-product {
    top: 0px !important;
    padding-bottom: 12.5px;
  }
  .title-product-detail.f-spectral {
    text-align: center;
  }
  #column-desc-product .title-product-detail.f-spectral {
    text-align: left;
  }
  .section-2-product-detail .column:nth-child(1){
    padding: 0px !important;
  } 
  .section-2-product-detail .column:nth-child(2){
    margin-bottom: 75px !important;
  } 
  .menu-list a.is-active {
    background: transparent !important;
    color:#E7BD7A !important;
  }
  .menu ul li a {
    text-align: left;
    color: #000000 !important;
    font-family: "Karla";
  }
  .nav-menu li a {
    padding: 0 31px;
    color: #333;
    font-size: 13px !important;
    font-weight: 600;
    margin: 0px 0px;
  }
  ul#sub-menu li a, ul#submenu-submenu li a {
    font-size: 11px !important;
    font-weight: 400;
  }
  #bars-mobile{
    padding: 0px 3rem;
  }
  section.sidebar-layout {
    position: absolute;
    top: 8px;
    width: 100% !important;
  }
  .b-sidebar .sidebar-content {
    width: 100% !important;
    box-shadow: 0px 0px 0px !important;
  }
  .b-sidebar .sidebar-content.is-light {
    background: #ffffff !important;
  }
  .mg-185 {
    margin-top: 85px !important;
    margin-bottom: 85px !important;
    position: relative;
  }
  .columns-tablet {
    display: grid;
    grid-template-columns: 50% auto;
  }
  ul.menu-list {
    padding: 0px 15px;
  }
  .carousel-arrow.is-hovered {
    opacity: 0 !important;
  }
  .column-center {
    padding: 0px !important;
    margin-bottom: 0px !important;
  }
  .column.wrap-img-gallery img, .column.wrap-img-gallery {
    min-height: 250px;
  }
  .img-why-top, .img-why-bottom, .title-why div{
    width: 25px !important;
  }
  .title-page h1.title.is-1.f-spectral, .section-1-gallery .title-product-detail, .section-1-product h1{
    color: #0A183B !important;
  }
  footer .columns.columns-tablet .column:nth-child(2), footer .columns.columns-tablet .column:nth-child(3), footer .columns.columns-tablet .column:nth-child(4) {
    padding: 18px;
  }
  .wrap-contact-footer {
    grid-template-columns: 10% 90%;
  }
  .lsp2 {
    padding: 60px 15px 120px !important;
    margin: 0px 15px 60px !important;
  }
  #contact-form .column.is-1{
    display: none;
  }
  #contact-form textarea.textarea{ 
    margin-top: 22.5px;
  }
}

/* mobile  */
@media screen and (max-width: 568px) {
  #bars-mobile{
    padding: 0px 15px;
  }
  .nav-menu-mobile ul li, .nav-menu-mobile li {
    padding: 9px 30px;
    text-align: left;
  }
  .filter-trigger label.b-checkbox.checkbox.button, .footer-filter button {
    font-size: 11px !important;
  }
  .filter-wrap {
    width: 99% !important;
    position: relative;
  }
  .footer-filter {
      margin: 0.35rem 0rem;
  }
  .input-checkbox {
    grid-template-columns: auto auto auto auto;
  }
  .lightbox .lb-nav {
    top: 100% !important;
    height: 25% !important;
  }
  .lightbox .lb-nav a.lb-next, .lb-nav a.lb-prev {
    width: 18% !important;
  }
  .lb-closeContainer {
    position: absolute !important;
    top: 0px !important;
    right: 4% !important;
  }
  #lightbox {
    top: 225px !important;
  }
  .button-general {
    padding: 17.5px 25px;
  }
  footer {
    padding: 48px 48px 28px;
  }
  .section-home-2 {
    margin: 0px 0px !important;
  }
  .rotate-icon {
    transform: rotate(90deg);
  }
  h1.title.is-1.f-spectral {
    font-size: 38px !important;
  }
  .column-center {
    padding: 0px 0px !important;
  }
  .section-2-footer a, .section-2-footer p span, .section-2-footer p {
    font-size: 18px;
  }
  #contact-form textarea.textarea{ 
    margin-top: 20px;
  }
  /* li ul li ul {
    display: block !important;
  } */
  #contact-form input.input {
    height: 50px !important;
  }
  #contact-form input.input, #contact-form textarea.textarea {
    font-size: 18px !important;
  }
  .columns-tablet {
    display: block;
  }
  .columns-mobile {
    display: grid;
    grid-template-columns: 50% auto;
  }
  .is-section-1 {
    margin-top: 0px !important;
  }
  h1.title.is-1.p-25.f-spectral {
    font-size: 35px;
    line-height: 45px;
  }
  .hero-body .title-banner{
    font-size: 35px;
    line-height: normal;
  }
  .hero-body .desc-banner {
    font-size: 14px;
  }
  .hero-body img.img-banner {
    float: none;
    margin-top: 25px;
    margin-right: 20%;
  }
  .hero.is-medium .hero-body {
    padding: 4em 2rem 5em !important;
  }
  .sidebar-layout {
    z-index: 99999 !important;
  }
  .is-fluid.list-product {
    padding: 0px 25px !important;
  }
  .wrap-img-product-list {
    height: 396px;
    position: relative;
  }
  .wrap-img-product-list img{
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 25px;
  }
  .section-2-product-detail .column:nth-child(2) {
    margin-bottom: 50px !important;
  }
  .box-product-list {
    margin-bottom: 50px;
  }
  .overlay, .wrap-img:hover .overlay {
    height: 307px;
  }
  .section-home-2 {
    padding: 0px;
  }
  .overlay a {
    top: 45%;
    right: 50%;
    transform: translate(45%, -50%);
  }
  .overlay a {
    padding: 8px 28px;
  }
  .section-home-2 .title {
    font-size: 24px !important;
  }
  .wrap-img img {
    min-height: auto;
  }
  .wrap-img-product-list img {
    max-height: 300px;
  }
  .title-custom {
    font-size: 30px;
    line-height: normal;
  }
  .title-product-detail.f-spectral {
    font-size: 28px; 
  }
  .breadcumb {
    font-size: 20px;
  }
  .column-desc-product .title-product-detail {
    text-align: left;
  }
  .column-desc-product {
    padding-bottom: 125px;;
  }
  .desc-product {
    line-height: 27px;
  }
  .wrap-img-detail {
    margin-bottom: 30px;
  }
  .section-2-faq, .section-2-gallery, .section-1-gallery{
    padding: 0px 25px !important;
  }
  .section-1-gallery {
    padding: 75px 15px!important;
  }
  p.card-header-title.f-spectral {
    font-size: 14px;
    line-height: normal;
  }
  .card-header-icon {
    padding: 0.25rem 0rem 0.25rem 1rem !important;
  } 
  .toogle-faq {
    margin-bottom: 72px;
  }
  .faq-title {
    background-position: 85% 50%;
  }
  .container-gallery {
    display: grid;
    grid-template-columns: auto;
  }
  .lb-closeContainer {
    right: 0% !important;
    left: 98% !important;
  }
  section.sidebar-layout {
    position: absolute;
    top: 5px;
    width: 100% !important;
  }
  .column-center {
    padding: 0px !important;
  }
  .p-testimonials {
    padding: 0px 35px;
  }
  .slick-prev {
    left: -12px !important;
  }
  .slick-next {
    right: -18px !important;
  }
  .bg-gradient {
    padding: 75px 35px !important;
  }
  .title-why img {
    width: auto;
    height: auto;
  }
  .title-why {
    display: grid;
    grid-template-columns: 10% 90%;
  }
  .content-why {
    padding-left: 40px;
  }
  .is-bg-banner img.img-banner {
    min-height: auto !important;
    height: auto;
  }
  .filter-trigger {
    top: 65px;
    left: 0rem !important;
  }
}

@media screen and (max-width:376px) {
  .button-general {
    padding: 17.5px 25px;
  }
}
</style>
