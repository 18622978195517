<template>
    <div>
    <Header/>
        <div class="wrap-title-product m-100 is-section-1">
            <div class="container is-fluid section-1-product-list">
                <div class="columns">
                    <div class="column">
                        <h1 class="title-product-detail f-spectral">{{ this.$route.params.slug }}</h1>
                    </div>
                    <div class="column column-breadcumb">
                        <div class="breadcumb f-spectral"><a href="/">HOME</a> / {{ this.$route.params.slug }}</div>
                    </div>
                </div>
            </div>
        </div>       
        <div class="container is-fluid list-product">
            <div class="columns-product">
                <div class="" v-for="(product, index) in productCategory" :value="index" :key="product.index">
                    <div class="box-product-list">
                            <router-link :to="{ name: 'Detail_Product', params: { slug: product.slug } }">
                                <div class="wrap-img-product-list">
                                        <img :src="url_domain.concat(product.image_featured.url)" alt="">
                                </div>
                            </router-link>
                        <div class="wrap-desc-product-list">
                            <div class="name-product-list f-spectral">
                                {{ product.name }}
                            </div>
                            <div class="cat-product-list f-karla">
                                {{ product.category_product.category_name }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="column">
                    <div class="box-product-list">
                        <div class="wrap-img-product-list">
                            <img src="../assets/img/pngwing 33.png" alt="">
                        </div>
                        <div class="name-product-list f-spectral">
                            Monsoons Blind
                        </div>
                        <div class="cat-product-list f-karla">
                            Blind
                        </div>
                    </div>
                </div> -->
            </div>
            <div v-if="!productCategory.length">
                <h4 class="f-karla text-not-found">
                    Product dengan kategori ini tidak ditemukan.
                </h4>
            </div>
        </div>    

        <!-- <div class="container">
            <div class="card-body">
                <div v-for="item in pageOfItems" :key="item.id">{{item.name}}</div>
            </div>
            <div class="card-footer pb-0 pt-3">
                <jw-pagination :items="exampleItems" @changePage="onChangePage"></jw-pagination>
            </div>
        </div> -->

            <ul class="pagination-product">
                <li @click="first" v-if="paginationGreaterThanOne"><font-awesome-icon :icon="['fas', 'chevron-left']" /></li>
                <li class="class-pagination" v-for="(itemPagination, index) in forLoopPagination"  @click="showData(itemPagination)" :key="index" :class="{ 'active': active_el === itemPagination }">
                    
                        {{ itemPagination }}
                    
                </li>
                <li @click="last" v-if="paginationOne && forLoopPagination != 1"><font-awesome-icon :icon="['fas', 'chevron-right']" /></li>
            </ul>

        <Footer/>
        <router-view/>
    </div>
</template>

<script>

// const exampleItems = [...Array(150).keys()].map(i => ({ id: (i+1), name: 'Item ' + (i+1) }));
import Header from '../components/Header'
import Footer from '../components/Footer'
const axios = require('axios')
export default {
    name: "List_Product",
    metaInfo () {
        return {
            title: this.nameCategory,
            meta: [
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords },
            ]    
        }
    },
    data() {
        return {
            productCategory: {},
            url_domain: process.env.VUE_APP_STRAPI_URL,
            metaTitle: '',
            metaDescription: '',
            keywords: '',
            nameCategory: null,
            detailCategory: [],     
            productToShow: 3,
            totalProduct: 0,
            pageOfItems: [],
            getNumberPagination: null,
            forLoopPagination: null,
            paginationGreaterThanOne: false,
            paginationOne: true,
            lastLength: null,
            active_el: 1
        }
    },
    components: {
        Header,
        Footer,
    },
    created() {
        
        axios.get(`${this.url_domain}/products?category_product.category_name=${this.$route.params.slug}`).then(responseForLength => {
            this.getProductLength = responseForLength.data

            this.getNumberPagination = this.getProductLength.length / 3 
            // console.log(this.getProductLength.length)
            this.forLoopPagination = Math.ceil(this.getNumberPagination)
        })

        axios.get(`${this.url_domain}/products?_start=0&_limit=3&category_product.category_name=${this.$route.params.slug}`).then(response => {
            this.productCategory = response.data
        })
        
        axios.get(`${this.url_domain}/category-products?category_name=${this.$route.params.slug}`).then(responseCategory => {
            this.detailCategory = responseCategory.data[0]
            this.productCategoryForSEO = responseCategory.data[0].seo
            this.metaTitle = this.productCategoryForSEO.metaTitle
            this.metaDescription = this.productCategoryForSEO.metaDescription
            this.keywords = this.productCategoryForSEO.keywords
            // console.log(this.detailCategory)
            this.nameCategory = responseCategory.data[0].category_name
            // console.log(this.nameCategory)
        })
        
    },
    mounted() {
        this.totalProduct = this.productCategory.length;
    },
    methods: {
        // onChangePage() {
        //     axios.get(`${this.url_domain}/products?_start=3&_limit=6&category_product.category_name=${this.$route.params.slug}`).then(response => {
        //         this.productCategory = response.data
        //         // console.log(this.productCategoryForSEO)
        //     })
        // },

        showData(itemPagination) {
            console.log(itemPagination)
            this.active_el = itemPagination
            var start = itemPagination * 2
            if(itemPagination >= 2) {
                axios.get(`${this.url_domain}/products?_start=${start}&_limit=3&category_product.category_name=${this.$route.params.slug}`).then(response => {
                    this.productCategory = response.data
                    // console.log(this.productCategoryForSEO)
                })
                this.paginationGreaterThanOne = true
                this.paginationOne = false
            } else {
                axios.get(`${this.url_domain}/products?_start=0&_limit=3&category_product.category_name=${this.$route.params.slug}`).then(response => {
                    this.productCategory = response.data
                    // console.log(this.productCategoryForSEO)
                this.paginationGreaterThanOne = false
                this.paginationOne = true
                })
            }
        },
        first() {
            axios.get(`${this.url_domain}/products?_start=1&_limit=3&category_product.category_name=${this.$route.params.slug}`).then(response => {
                this.productCategory = response.data
            })
        },
        last() {
            axios.get(`${this.url_domain}/products?&category_product.category_name=${this.$route.params.slug}`).then(responseLast => {
                this.getLength = responseLast.data
                this.lastLength = this.getLength.length % 3 
                // console.log(this.lastLength);
                axios.get(`${this.url_domain}/products?_start=${this.lastLength}&_limit=3&category_product.category_name=${this.$route.params.slug}`).then(response => {
                    this.productCategory = response.data
                })
            })
        }
    }
}
</script>

<style>
    .pagination-product {
        display: flex;
        justify-content: center;
        margin-bottom: 100px;
    }
    .pagination-product .class-pagination.active {
        background: transparent;
        color: #D6C09F;
        outline: none !important;
    }
    .pagination-product .class-pagination{
        position: relative;
        display: inline-block;
        width: auto;
        background: transparent;
        color: #142E70;
        font-size: 18px;
        font-weight: 400;
        margin: 0 12px;
        padding: 0;
        vertical-align: top;
        text-decoration: none;
        cursor: pointer;
        white-space: nowrap;
        -webkit-transition: color .2s ease-out;
        -moz-transition: color .2s ease-out;
        transition: color .2s ease-out;
    }
    .pagination-product li svg {
        padding-top: 2px;
        padding-left: 3px;
        cursor: pointer;
        white-space: nowrap;
    }
    .columns-product {
        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
        column-gap: 12px;
    }
    @media screen and (max-width: 1441) and (min-width:1080px) {
        .wrap-img-product-list {
            position: relative;
        }
        .wrap-img-product-list img {
            transition: transform 0.8s;
            max-height: 425px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: auto;
        }
    }
    @media screen and (max-width: 1079) and (min-width:767px) {
        .columns-product {
            display: grid;
            grid-template-columns: auto auto;
        }
    }
    @media screen and (max-width: 767px) {
        .columns-product {
            display: grid;
            grid-template-columns: auto;
        }
    }
</style>