<template>
    <div>
    <Header/>
        <div class="wrap-title-product is-section-1">
            <div class="container section-1-product is-fluid">
                <div class="columns">
                    <div class="column">
                        <h1 class="title-product-detail f-spectral">{{ product.name }}</h1>
                    </div>
                    <div class="column column-breadcumb">
                        <div class="breadcumb f-spectral"><a href="/"> HOME </a> / <a :href="$router.resolve({ name: 'List_Product', params: { slug: product.category_product.category_name } }).href"> {{ product.category_product.category_name }} </a> / {{ product.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container section-2-product p-mobile section-2-product-detail is-fluid">
            <div class="columns ">
                <div class="column is-half" id="gallery-product">
                    
                    <!-- <template> -->
                            <!-- {{ domain.concat(selectedVariant.gallery_product ) }} -->
                     <!-- <div> -->
                        <!-- <a :href="domain.concat(image.formats.large.url)" data-lightbox="gallery-img" id="gallery-img"  v-for="(image, i) in selectedVariant.gallery_product" :key="selectedVariant.id + i">
                            <div class="wrap-img-detail">
                                <img v-if="image.formats.large.url != null" :src="domain.concat(image.formats.large.url)" alt="">  
                                <img v-else :src="domain.concat(image.formats.url)" alt="">  
                            </div>
                        </a> -->
                        <a :href="domain.concat(image.url)" data-lightbox="gallery-img" id="gallery-img"  v-for="(image, i) in selectedVariant.gallery_product" :key="selectedVariant.id + i">
                            <div class="wrap-img-detail">
                                <img v-if="image.url != null" :src="domain.concat(image.url)" alt="">  
                                <img v-else :src="domain.concat(image.formats.url)" alt="">  
                            </div>
                        </a>
                     <!-- </div>  -->
                    <!-- </template> -->

                    <!-- <a href="https://i.ibb.co/nn7kcqg/group1.png" data-lightbox="gallery-img" id="gallery-img">
                        <div class="wrap-img-detail">
                            <img src="https://i.ibb.co/nn7kcqg/group1.png" alt="">
                        </div>
                    </a> -->
                </div>
                <div class="column">
                    <div class="column-desc-product" id="column-desc-product">
                    <div class="title-product-detail f-spectral">
                        {{ product.name }}
                    </div>
                    <div class="desc-product f-karla">
                        {{ product.description }} 
                    </div>
                    <div class="sku-category f-karla">
                        SKU : {{ selectedVariant.sku }} <br>
                        Category : 
                        {{ product.category_product.category_name }} 
                    </div>
                    <div class="color-material f-karla">
                        
                        <select v-model="selectedVariant.id" @change="onSelectVariant($event.target.value)" placeholder="Color - Material">
                            <option :value="variant.id" v-for="(variant, i) in product.variant" :key="i">{{variant.color}} - {{variant.material}}</option>
                        </select>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
        <router-view/>
    </div>
</template>

<script src="../assets/lightbox/js/lightbox.js" type="text/javascript"></script>
<script>
import Header from '../components/Header'
import Footer from '../components/Footer'
import jQuery from 'jquery'
import lightbox from '../assets/lightbox/js/lightbox'
const axios = require('axios')
export default ({
    name: "Detail-Product",
    metaInfo () {
        return {
            title: this.product.name,
            meta: [
                { charset: 'utf-8' },
                { name: 'title', content: this.metaTitle },
                { name: 'description', content: this.metaDescription },
                { name: 'keywords', content: this.keywords },
            ]    
        }
    },
    data() {
        return {
            product: {},
            selectedVariant: {},
            domain: process.env.VUE_APP_STRAPI_URL,
            metaTitle: '',
            metaDescription: '',
            keywords: ''
        }
    },
    created() {
        axios.get(`${this.domain}/products?slug=${this.$route.params.slug}`).then(response => {
            this.product = response.data[0]
            this.metaTitle = this.product.seo.metaTitle
            this.metaDescription = this.product.seo.metaDescription
            this.keywords = this.product.seo.keywords
            
            // console.log(this.product)
            this.onSelectVariant(this.product.variant[0].id)
        })

        if(window.location.href == "https://tujuhdecor.roketin.xyz/detail-product/" || window.location.href == "https://tujuhdecor.com/detail-product/") {
            jQuery("#gallery-img").click(function(){
                jQuery( "#lightbox" ).addClass( "detailProduct" );
            })
        }
    },
    methods: {
        onSelectVariant (e) {
            let id = e
            // get variant yg dipilih
            // console.log(this.product.variants)
            let selectedVariant = this.product.variant.filter(variant => variant.id === parseInt(id))
            if (selectedVariant.length > 0) {
            // masukkan varian yang dipilih ke this.selectedVariant
                this.selectedVariant = JSON.parse(JSON.stringify(selectedVariant[0]))
            }
        } 
    },
    mounted() {

        window.addEventListener('scroll', function() {
            const scrollHeight = window.pageYOffset;
            if (scrollHeight > 285) {
                document.getElementById("column-desc-product").style.top = '75px';
            } else {
                document.getElementById("column-desc-product").style.top = '625px';
            }
        })
        
        jQuery("#filter-button").click(function(){
            jQuery( ".filter-trigger" ).toggle();
        })

        lightbox.option({ })

        if(window.location.href == "https://tujuhdecor.roketin.xyz/detail-product/" || window.location.href == "https://tujuhdecor.com/detail-product/") {
            jQuery("#gallery-img").click(function(){
                jQuery( "#lightbox" ).addClass( "detailProduct" );
            })
        }

    },
    components: {
        Header,
        Footer
    }
})



</script>

<style>
.lb-closeContainer {
    position: absolute !important;
    top: -35px !important;
    right: 15% !important;
}
.lb-data .lb-details {
        width: 100% !important;
}
.lb-data .lb-number {
    text-align: center !important;
    font-size: 20px !important;
    font-family: "Karla" !important;
    padding-bottom: 0.5em !important;
    background: #fff !important;
}
.lb-data {
    padding: 0px !important;
}
.lb-dataContainer {
    padding-top: 0px !important;
}
</style>
